import { Get, Put } from 'config/apiServiceConfig'

export interface GetFeatureFlagsParamsInterface {
  featureKey?: string
  pageSize?: number | string
  pageIndex?: number | string
}

export enum DeviceIdEnum {
  WEB = 1,
  ANDROID = 2,
  IOS = 3,
}
export interface GetFlagLocationParamsInterface {
  deviceId: DeviceIdEnum
  locationName: string
  locationType: string
  pageSize: number
  pageIndex: number
}

export type FeatureFlagPayloadType = {
  locationId: number
  active: boolean
}
export interface UpdateFeatureFlagInterface {
  deviceId: DeviceIdEnum
  featureFlags: Array<FeatureFlagPayloadType>
}

export const fetchFeatureFlags = ({
  featureKey,
  pageSize,
  pageIndex,
}: GetFeatureFlagsParamsInterface) =>
  Get({
    url: `/api/admin/hub-feature?pageSize=100`,
    params: {
      featureKey,
      pageSize,
      pageIndex,
    },
  })

export const fetchLocations = (
  featureId: number,
  params: Partial<GetFlagLocationParamsInterface>,
) =>
  Get({
    url: `/api/admin/hub-feature/${featureId}/location`,
    params,
  })

export const updateFeatureFlag = (featureId: number, data: UpdateFeatureFlagInterface) =>
  Put({
    url: `/api/admin/hub-feature/${featureId}/location/bulk-update`,
    data,
  })
