import {
  AUTH_ADMIN,
  AUTH_INVENTORY_CONTROLLER,
  AUTH_LP,
  AUTH_SPV_HUB,
} from 'middleware/privateRoute'
import { AppDispatchType, RootStateType } from 'store'
import {
  GetSoApprovalRequestType,
  getSupplyOrderApprovalDetailV2,
} from 'utils/apiList/supplyOrderApproval'
import { callErrorMsg } from 'helpers/errorMsg'
import { fetchGetSupplyOrderApproval } from './supplyOrderApprovalAsyncThunk'
import {
  setDataOnFetchDetailSucceed,
  setQueryData,
  resetTableData,
} from './supplyOrderApprovalSlice'

export const actSetQuery =
  (paramsQuery: GetSoApprovalRequestType) =>
  (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const {
      auth: { userData },
      supplyOrderApproval,
    } = getState()
    let status = paramsQuery?.status || supplyOrderApproval.queryData?.status

    if (!supplyOrderApproval.queryData?.status && !paramsQuery?.status) {
      userData.authorities.forEach((item: { name: string }) => {
        if (item.name === AUTH_INVENTORY_CONTROLLER || AUTH_ADMIN) {
          status = 'WAITING_APPROVAL'
        }
        if (item.name === AUTH_SPV_HUB) {
          status = 'COMPLETED'
        }
        if (item.name === AUTH_LP) {
          status = 'IN_REVIEW'
        }
      })
    }

    const queryData = {
      ...supplyOrderApproval.queryData,
      ...paramsQuery,
      status,
    }

    dispatch(setQueryData(queryData))
    dispatch(fetchGetSupplyOrderApproval(queryData))
    if (queryData?.supply_order_id || queryData?.date || queryData?.created_by) {
      dispatch(fetchGetSupplyOrderApproval(queryData))
      return
    }
    dispatch(resetTableData())
  }

export const fetchGetSupplyOrderApprovalDetailV2 =
  (id: number) => async (dispatch: AppDispatchType) => {
    try {
      const {
        data: { data },
      } = await getSupplyOrderApprovalDetailV2(id)

      dispatch(setDataOnFetchDetailSucceed(data))
    } catch (err) {
      callErrorMsg(err)
    }
  }
