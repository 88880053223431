import { memo } from 'react'
import styled from 'styled-components'
import { TextBody, Button } from 'components'
import iconCheck from 'assets/images/check.svg'

const OnUploadSuccess = ({
  onResetFile,
  resultUpload,
  dataName,
  linkToGoWhenSuccess,
  customSuccessDescription,
}) => (
  <DescriptionContainer>
    <IconUploadContainer>
      <IconUpload style={{ width: '28px', height: '28px' }} src={iconCheck} />
    </IconUploadContainer>
    <TextBody weight="bold" className="pointer" color="black">
      Yay, kamu berhasil menambahkan [{resultUpload?.successData} / {resultUpload?.totalData}]{' '}
      {dataName} baru
    </TextBody>
    <TextDescription>
      <FileDescription>
        {customSuccessDescription ? (
          customSuccessDescription()
        ) : (
          <TextBody weight="light" color="black">
            data akan muncul di daftar {dataName} dalam beberapa saat.
          </TextBody>
        )}
      </FileDescription>
      <NavigationContainer>
        <ButtonWhite onClick={onResetFile} style={{ marginLeft: 'auto', marginRight: '24px' }}>
          Upload Lagi
        </ButtonWhite>
        {linkToGoWhenSuccess && (
          <ButtonBlue onClick={linkToGoWhenSuccess} style={{ marginRight: 'auto' }}>
            Daftar {dataName}
          </ButtonBlue>
        )}
      </NavigationContainer>
    </TextDescription>
  </DescriptionContainer>
)
export default memo(OnUploadSuccess)

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const IconUploadContainer = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
`

const IconUpload = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 34px;
`

const TextDescription = styled.div`
  text-align: center;
`
const FileDescription = styled.div`
  margin-top: 8px;
`

const NavigationContainer = styled.div`
  display: flex;
  margin-top: 24px;
`

const ButtonWhite = styled(Button)`
  background: white;
  color: #246ee5;
  border: 1px solid #246ee5;
  min-width: 128px;
`

const ButtonBlue = styled(Button)`
  background: #246ee5;
  color: white;
  border: 1px solid #246ee5;
  min-width: 128px;
`
