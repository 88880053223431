import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import ModifierRouterList, { ModifierNamedRoutesType, ModifierPathListsType } from './modifier'

type NamedRoutesType =
  | 'VariantModuleRouter'
  | 'ProductVariantRouter'
  | 'AddProductVariantRouter'
  | 'EditProductVariantRouter'
  | 'MasterVariantRouter'
  | 'ConversionRuleRouter'
  | ModifierNamedRoutesType
  | 'PriceTagRouter'

type PathListsType =
  | '/dashboard/product-variant'
  | '/dashboard/product-variant/add'
  | '/dashboard/product-variant/edit/:productVariantId'
  | '/dashboard/master-variant'
  | '/dashboard/conversion-rule'
  | ModifierPathListsType
  | '/dashboard/price-tag'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const ProductVariantPage = lazy(() => import('../pages/ProductVariant'))
const AddProductVariantPage = lazy(() => import('../pages/AddProductVariant'))
const MasterVariant = lazy(() => import('../pages/MasterVariant'))
const ConversionRule = lazy(() => import('../pages/ConversionRule'))
const PriceTag = lazy(() => import('../pages/PriceTag'))

export const VariantModuleRouter: RouterInstanceType = {
  name: 'VariantModuleRouter',
  Component: ProductVariantPage,
  path: '/dashboard/product-variant',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_PRODUCT_MANAGER',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_MERCHANDISING_HUB',
    'AUTH_HO_MERCHANDISING',
  ],
  isDashboard: false,
}

const ProductVariantRouter: RouterInstanceType = {
  name: 'ProductVariantRouter',
  Component: ProductVariantPage,
  path: '/dashboard/product-variant',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_PRODUCT_MANAGER', 'AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB'],
  isDashboard: true,
}

const AddProductVariantRouter: RouterInstanceType = {
  name: 'AddProductVariantRouter',
  Component: AddProductVariantPage,
  path: '/dashboard/product-variant/add',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_PRODUCT_MANAGER', 'AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB'],
  isDashboard: true,
}

const EditProductVariantRouter: RouterInstanceType = {
  name: 'EditProductVariantRouter',
  Component: AddProductVariantPage,
  path: '/dashboard/product-variant/edit/:productVariantId',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_PRODUCT_MANAGER', 'AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB'],
  isDashboard: true,
}

const MasterVariantRouter: RouterInstanceType = {
  name: 'MasterVariantRouter',
  Component: MasterVariant,
  path: '/dashboard/master-variant',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB', 'AUTH_HO_MERCHANDISING'],
  isDashboard: true,
}

const ConversionRuleRouter: RouterInstanceType = {
  name: 'ConversionRuleRouter',
  Component: ConversionRule,
  path: '/dashboard/conversion-rule',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_ADMIN',
    'AUTH_PRODUCT_MANAGER',
    'AUTH_QUALITY_ENGINEERING',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_MERCHANDISING_HUB',
    'AUTH_MERCHANDISING_ADMIN',
    'AUTH_HO_MERCHANDISING',
    'AUTH_HO_SUPPLY_CHAIN',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

const PriceTagRouter: RouterInstanceType = {
  name: 'PriceTagRouter',
  Component: PriceTag,
  path: '/dashboard/price-tag',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_ASTRO_GO_MANAGER',
    'AUTH_ASTRO_GO_SPV',
    'AUTH_ASTRO_GO_SHIFT_LEAD',
    'AUTH_ASTRO_GO_STAFF',
  ],
  isDashboard: true,
}

const ProductRouter: RouterInstanceType[] = [
  ProductVariantRouter,
  AddProductVariantRouter,
  EditProductVariantRouter,
  MasterVariantRouter,
  ConversionRuleRouter,
  ...ModifierRouterList,
  PriceTagRouter,
]

export const ProductRouterDetail = generateRouterDetail(ProductRouter)

export default ProductRouter
