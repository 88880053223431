import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchFeatureFlags,
  GetFeatureFlagsParamsInterface,
  fetchLocations,
  GetFlagLocationParamsInterface,
  updateFeatureFlag,
  UpdateFeatureFlagInterface,
} from 'utils/apiList/flagManagement'
import { callErrorMsg } from 'helpers/errorMsg'
import { SLICE_NAME, setFeatureFlags, setLoading, setLocations } from './flagManagementSlice'
import type { LocationResponseType } from './flagManagementSlice'

export const getFeatureFlags = createAsyncThunk(
  `${SLICE_NAME}/getFeatureFlags`,
  async (query: Partial<GetFeatureFlagsParamsInterface>, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true))
    try {
      const { data } = await fetchFeatureFlags(query)
      dispatch(setFeatureFlags(data))
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getFeatureDetails = createAsyncThunk(
  `${SLICE_NAME}/getFeatureDetails`,
  async (
    { featureId, query }: { featureId: number; query: Partial<GetFlagLocationParamsInterface> },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setLoading(true))
    try {
      const defaultParams: Partial<GetFlagLocationParamsInterface> = {
        pageSize: 10,
        pageIndex: 0,
      }
      const { data } = await fetchLocations(featureId, { ...defaultParams, ...query })
      dispatch(setLocations(data as LocationResponseType))
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const updateFeatureFlagLocations = createAsyncThunk(
  `${SLICE_NAME}/updateFeatureFlagLocations`,
  async (
    { featureId, payload }: { featureId: number; payload: UpdateFeatureFlagInterface },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setLoading(true))
    try {
      const { data } = await updateFeatureFlag(featureId, payload)
      dispatch(
        getFeatureDetails({
          featureId,
          query: { locationType: 'HUB', deviceId: payload.deviceId },
        }),
      )
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
