import { memo, useContext } from 'react'
import { useDispatch, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import store from 'storeContext'
import { RowAllOrder, NoData } from 'components'
import useAllowedAccessFor, { useViewOnly } from 'customHooks/useAllowedAccessFor'
import { setDialogState, setPopUpSkipDropOff, setSelectedOrderData } from 'redux/allOrder'
import allowedRoleList from 'middleware/allowedRoleOnFeature/order/allOrder'
import { TableCell, TableRow } from '@astro-ui/components'
import { setWaitingConfirmationOrderIdFocus } from 'storeContext/actions/allOrder'
import { fetchTimeslotListData } from 'redux/changeTimeslot'
import { useAppSelector } from 'store'

function TableAllOrder({
  data,
  hanleClickPopupCancel,
  handleClickPopUpSkipVerifyLocation,
  hanleClickModalDetail,
  hanleClickDone,
  setSelectedDataOrder,
  setOrderPoolingData,
  orderPoolingData,
  initialState,
  ids = [],
  handleOpenModalEditAddress,
  handleClickCallInstantCourier,
  handleClickCancelCourier,
  handleClickSkipHub,
  location,
  isAllOrderSuper,
  handleContactCustomer,
}) {
  const { authorities, isSidebarOpen } = useAppSelector(
    ({
      auth: {
        userData: { authorities },
      },
      app: {
        layout: { isSidebarOpen },
      },
    }) => ({ authorities, isSidebarOpen }),
    shallowEqual,
  )

  const dispatchRedux = useDispatch()
  const { dispatch: dispatchAllorder } = useContext(store.AllorderContext)

  /****
   *  RESTRICTION LIST FOR FEATURE
   *  USING CUSTOM HOOKS WITH MEMOIZATION
   *
   */

  const allowedForCancel = useAllowedAccessFor(authorities, allowedRoleList.cancel)

  const allowedForFinish = useAllowedAccessFor(authorities, allowedRoleList.finish)

  const allowedForSkipVerifyLocation = useAllowedAccessFor(
    authorities,
    allowedRoleList.skipVerifyLocation,
  )

  const allowedForEditAddress = useAllowedAccessFor(authorities, allowedRoleList.editAddress)

  const allowedForCallInstantCourier = useAllowedAccessFor(
    authorities,
    allowedRoleList.callInstantCourier,
  )

  const isViewOnly = useViewOnly(allowedRoleList.viewOnly)

  const actionFinish = () => {
    return allowedForFinish
      ? [{ name: 'Selesaikan Pesanan', id: 2, handleClick: hanleClickDone }]
      : []
  }

  const actionCancel = () => {
    return allowedForCancel
      ? [{ name: 'Batalkan Pesanan', id: 6, handleClick: hanleClickPopupCancel }]
      : []
  }

  const actionSkipVerifyLocation = () => {
    return allowedForSkipVerifyLocation
      ? [
          {
            name: 'Lewati Verifikasi Lokasi',
            id: 3,
            handleClick: handleClickPopUpSkipVerifyLocation,
            checked: true,
          },
        ]
      : []
  }
  const actionEditAddress = () => {
    return allowedForEditAddress
      ? [
          {
            name: 'Edit Alamat',
            id: 4,
            handleClick: handleOpenModalEditAddress,
          },
        ]
      : []
  }
  const actionInstantCourier = () => {
    return allowedForCallInstantCourier && !isAllOrderSuper
      ? [
          {
            name: 'Panggil Kurir Instan',
            id: 1,
            handleClick: handleClickCallInstantCourier,
          },
        ]
      : []
  }
  const actionCancelInstantCourier = () => {
    return allowedForCallInstantCourier
      ? [
          {
            name: 'Batalkan Kurir Instan',
            id: 5,
            handleClick: handleClickCancelCourier,
          },
        ]
      : []
  }
  const actionSkipVerifyHub = () => {
    return isAllOrderSuper
      ? [
          {
            name: 'Lewati Verifikasi Tiba di Hub',
            id: 98,
            handleClick: handleClickSkipHub,
          },
        ]
      : []
  }

  const actionWaitingCustomerService = (item) => {
    if (item.order_status === 'Waiting Confirmation') {
      if (item.confirm_timestamp) {
        return [
          {
            name: 'Lanjutkan Pesanan',
            handleClick: () => dispatchAllorder(setWaitingConfirmationOrderIdFocus(item.order_id)),
          },
        ]
      }

      return [{ name: 'Chat Customer', handleClick: () => handleContactCustomer(item.order_id) }]
    }

    return []
  }

  const handleOpenSkipVerificationPackageDialog = (dataOrder) => {
    dispatchRedux(setDialogState('SKIP_VERIFICATION_PACKAGE_DIALOG'))
    dispatchRedux(setSelectedOrderData(dataOrder))
  }
  const handleOpenSkipVerificationDropOffDialog = (dataOrder) => {
    dispatchRedux(setPopUpSkipDropOff('SKIP_VERIFICATION_DROP_OFF'))
    dispatchRedux(setSelectedOrderData(dataOrder))
  }

  const handleClickChangeTimeslot = (dataOrder) => {
    dispatchRedux(fetchTimeslotListData(dataOrder))
  }

  const getActionList = (item) => {
    if (isViewOnly) return [{ name: 'Detail Order', id: 0, handleClick: hanleClickModalDetail }]

    return [
      ...actionWaitingCustomerService(item),
      { name: 'Detail Order', id: 0, handleClick: hanleClickModalDetail },
      { name: 'Pindah Time Slot', id: 100, handleClick: handleClickChangeTimeslot },
      ...actionInstantCourier(),
      ...actionFinish(),
      ...actionSkipVerifyLocation(),
      ...actionEditAddress(),
      ...actionCancelInstantCourier(),
      ...[
        {
          name: 'Lewati Verifikasi Paket',
          id: 99,
          handleClick: handleOpenSkipVerificationPackageDialog,
        },
      ],
      ...[
        {
          name: 'Lewati Verifikasi Drop Off',
          id: 97,
          handleClick: handleOpenSkipVerificationDropOffDialog,
        },
      ],
      ...actionSkipVerifyHub(),
      ...actionCancel(),
    ]
  }

  const checkDashUp = (data, index, items) => {
    return (
      data[index - 1]?.pool_number == items.pool_number &&
      items.pool_number !== null &&
      items.pool_number
    )
  }

  const checkDashDown = (data, index, items) => {
    return (
      data[index + 1]?.pool_number == items.pool_number &&
      index !== data.length - 1 &&
      items.pool_number !== null &&
      items.pool_number
    )
  }

  const renderTableRow = () => {
    return data.map((item, index) => (
      <RowAllOrder
        key={item.order_invoice + 11 + index}
        actionList={getActionList(item)}
        data={item}
        setSelectedDataOrder={setSelectedDataOrder}
        setOrderPoolingData={setOrderPoolingData}
        orderPoolingData={orderPoolingData}
        ids={ids}
        handleClickCallInstantCourier={handleClickCallInstantCourier}
        location={location}
        dashUp={checkDashUp(data, index, item)}
        dashDown={checkDashDown(data, index, item)}
        isAllOrderSuper={isAllOrderSuper}
      />
    ))
  }

  return (
    <WrapperTable isExpand={isSidebarOpen}>
      <Container cellSpacing={0}>
        <thead>
          <Tr>
            <Th ratio="2" badge="40px">
              <OrderIDWrapper>
                <div>ORDER ID</div>
              </OrderIDWrapper>
            </Th>
            <Th width="300" ratio="2">
              PEMESAN
            </Th>
            <Th width="150" ratio="1" style={{ textAlign: 'center' }}>
              {!isAllOrderSuper ? 'PESANAN MASUK' : 'TIMESLOT'}
            </Th>
            {!isAllOrderSuper ? (
              <Th ratio="1" textAlign="center">
                SLA/TIMER
              </Th>
            ) : (
              <Th ratio="1" textAlign="center">
                TIMER
              </Th>
            )}
            <Th width="240" ratio="1" style={{ textAlign: 'center' }}>
              {!isAllOrderSuper ? 'HUB' : 'LOKASI'}
            </Th>
            <Th ratio="2" textAlign="center">
              STATUS
            </Th>
            <Th ratio="1" style={{ paddingLeft: 24 }}>
              TOTAL
            </Th>
            <Th ratio="1">QTY</Th>
            <Th ratio="1" textAlign="end" style={{ paddingRight: 16 }}>
              AKSI
            </Th>
          </Tr>
        </thead>
        <tbody>{renderTableRow()}</tbody>
      </Container>
      {data?.length === 0 && <NoData />}
    </WrapperTable>
  )
}

export default memo(TableAllOrder)

const WrapperTable = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f3f6fa;
  ::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    background-color: #f3f4f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { colors } }) => colors.grey};
    height: 1px;
  }
`

const Container = styled.table`
  width: 100%;
`
const Tr = styled(TableRow)`
  background-color: #f3f4f5;
  padding: 16px;
  border-spacing: 0px;
`
const Th = styled(TableCell)`
  background-color: #f3f4f5;
  color: #212121;
  text-transform: uppercase;
  font-weight: 700;
`

const OrderIDWrapper = styled.div`
  display: flex;
`
