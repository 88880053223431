import { Get, Post, Put, Delete } from 'config/apiServiceConfig'
import {
  BannerHighlightType,
  DynamicChannelBackgroundColorsType,
  ProductHighlightType,
} from 'features/CategoryAndPosisi/@types/DynamicChannelAddEdit'

export type DynamicChannelSectionType = {
  id: number
  name: string
}

export type DynamicChannelVariantType = {
  id: number
  name: string
}

export type DynamicChannelCustomLogicType = {
  id: number
  name: string
}

export type DynamicChannelType = {
  id: number
  title: string
  section_name: string
  hubs: {
    location_id: number
    location_name: string
  }[]
  start_date: number
  end_date: number
  is_active: boolean
}

export type LocationType = {
  location_id: number
  location_name: string
}

export type CategoryType = {
  category_id: number
  category_name: string
}

export type GetDynamicChannelResponseType = {
  data: {
    content: DynamicChannelType[]
    number: number
  }
}

export type GetDynamicChannelSectionResponseType = {
  data: DynamicChannelSectionType[]
}

export type GetDynamicChannelCustomLogicResponseType = {
  data: DynamicChannelCustomLogicType[]
}

export type GetDynamicChannelVariantResponseType = {
  data: DynamicChannelVariantType[]
}

export type GetDynamicChannelParamsType = {
  page_number?: number
  page_size?: number
}

export type GetDynamicChannelByIdResponseType = {
  data: {
    id: number
    title: string
    dynamic_channel_type: DynamicChannelVariantType
    dynamic_channel_section: DynamicChannelSectionType
    catalogue: CategoryType
    category: CategoryType[]
    logic: DynamicChannelCustomLogicType
    is_active: boolean
    hubs: LocationType[]
    start_date: number
    end_date: number
    bg_color: DynamicChannelBackgroundColorsType
    banners: {
      banner_image: string
      banner_id: number
      end_time: number
      start_time: number
      url_image: string
      name: string
      position: number
    }[]
    banner_category_highlights: BannerHighlightType[]
    product_category_highlights: ProductHighlightType[]
  }
}

export type PutPostDynamicChannelPayloadType = {
  title: string
  dynamic_channel_type_id: number
  dynamic_channel_section_id: number
  catalogue_id: number | null
  category_id: number[]
  logic_id: number | null
  hubs: number[]
  start_date: number
  end_date: number
  banners: {
    banner_id: number
    position: number
  }[]
}

export type PostDynamicChannelActiveStatusType = {
  id: number
  is_active: boolean
}

export const getDynamicChannel = (params?: GetDynamicChannelParamsType) =>
  Get<GetDynamicChannelResponseType>({ url: '/api/dynamic-channel', params })

export const getDynamicChannelSection = () =>
  Get<GetDynamicChannelSectionResponseType>({ url: '/api/dynamic-channel/section' })

export const getDynamicChannelVariant = () =>
  Get<GetDynamicChannelVariantResponseType>({ url: '/api/dynamic-channel/type' })

export const getDynamicChannelCustomLogic = () =>
  Get<GetDynamicChannelCustomLogicResponseType>({ url: '/api/dynamic-channel/custom-logic' })

export const postDynamicChannel = (payload: PutPostDynamicChannelPayloadType) =>
  Post({ url: '/api/dynamic-channel', data: payload })

export const putDynamicChannel = (id: number, payload: PutPostDynamicChannelPayloadType) =>
  Put({ url: `/api/dynamic-channel/${id}`, data: payload })

export const getDynamicChannelById = (id: number) =>
  Get<GetDynamicChannelByIdResponseType>({ url: `/api/dynamic-channel/${id}` })

export const postDynamicChannelActiveStatus = (payload: PostDynamicChannelActiveStatusType) =>
  Post({ url: '/api/dynamic-channel/active', data: payload })

export const deleteDynamicChannel = (id: number) => Delete({ url: `/api/dynamic-channel/${id}` })
