export const hubPageLocationType = [
  'HUB',
  'VENDING MACHINE',
  'MEGAHUB',
  'MEGAHUB 2.0',
  'DIGITAL GOODS',
  'VIRTUAL',
  'ASTRO OFFLINE',
]
export const warehousePageLocationType = ['WAREHOUSE']
