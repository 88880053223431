import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { getQueryParamsObjectToString } from 'utils/helpers/router'
import { downloadFileBlob, downloadFileUrl } from 'utils/downloadFileUrl'
import { toastFailed, toastSuccess } from 'utils/toast'

import {
  fetchSKPList,
  fetchSKPDetails,
  fetchSKPProducts,
  fetchSKPProductDetails,
  fetchSKPAgreements,
  fetchSKPActualClaims,
  fetchSKPReimbursements,
  fetchSKProofFiles,
  postSKPProduct,
  postUploadFile,
  fetchDownloadData,
  putSKPProduct,
  deleteSKPProduct,
  postApproveActualClaim,
  postReimbursementItem,
  fetchVendors,
  postSKP,
  putSKP,
  duplicateSKP,
  deleteSKP,
  putSubmitReopenSKP,
  submitSKPData,
  rejectSKPData,
  approveSKPData,
  deleteDocument,
  deleteReimbursement,
  putActualQtyClaimItem,
  deleteActualQtyClaimItem,
  type SKPProductPayloadInterface,
  type PostItemReimbursementInterface,
  type PostSKPInterface,
  type SKPProductsInterface,
  type PostInternalSKPInterface,
} from 'features/Enterprise/SKP/utils/apiList'
import { fetchTnCList } from 'features/Enterprise/SKP/utils/apiList'

import {
  sliceName,
  setLoadingState,
  setSKPList,
  setSKPDetails,
  setSKPProducts,
  setSKPAgreements,
  setSKPActualClaims,
  setSKPReimbursement,
  setSKPProofFiles,
  setSKPProductDetails,
  setApprovedActualClaimStatus,
  setVendorList,
} from './skpSlice'
import { TermsAndConditionsDataInterface } from '../tnc'

export const getSKPList = createAsyncThunk(
  `${sliceName}/getSKPList`,
  async (_, { getState, dispatch }) => {
    try {
      dispatch(setLoadingState({ key: 'getSKP', isLoading: true }))
      const {
        skp: { query },
        router: {
          location: { pathname },
        },
      } = getState() as StoreStateType

      const updateQuery = { ...query }
      updateQuery.skp_no = query.skp_no?.replaceAll('/', '%2F')
      updateQuery.is_promotion_type_internal = pathname.includes('internal') ? 'True' : 'False'

      const parseQuery = getQueryParamsObjectToString(updateQuery)
      const { data } = await fetchSKPList(parseQuery)
      dispatch(setSKPList(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'getSKP', isLoading: false }))
    }
  },
)

export const getSKPDetails = createAsyncThunk(
  `${sliceName}/getSKPDetails`,
  async (skpNumber: string, { dispatch }) => {
    try {
      dispatch(setLoadingState({ key: 'getSKP', isLoading: true }))
      const { data } = await fetchSKPDetails(skpNumber)
      dispatch(setSKPDetails(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'getSKP', isLoading: false }))
    }
  },
)

export const getSKPProducts = createAsyncThunk(
  `${sliceName}/getSKPProducts`,
  async (
    { skpNumber, offset, limit = '5' }: { skpNumber: string; offset: number; limit?: string },
    { dispatch },
  ) => {
    try {
      const { data } = await fetchSKPProducts(skpNumber, offset, limit)
      dispatch(setSKPProducts(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getSKPProductDetails = createAsyncThunk(
  `${sliceName}/getSKPProductDetails`,
  async (id: string, { dispatch }) => {
    try {
      dispatch(setLoadingState({ key: 'getProduct', isLoading: true }))
      const { data } = await fetchSKPProductDetails(id)
      dispatch(setSKPProductDetails(data))
      return data
    } catch (error) {
      callErrorMsg(error)
      return 'error'
    } finally {
      dispatch(setLoadingState({ key: 'getProduct', isLoading: false }))
    }
  },
)

export const updateSKPProductData = createAsyncThunk(
  `${sliceName}/updateSKPProductData`,
  async (
    {
      skpNumber,
      payload,
      isUpdate,
    }: { skpNumber: string; payload: SKPProductPayloadInterface; isUpdate: boolean },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: true }))
      if (isUpdate) {
        await putSKPProduct(skpNumber, payload)
      } else {
        await postSKPProduct(skpNumber, payload)
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: false }))
    }
  },
)

export const deleteSKPProductItem = createAsyncThunk(
  `${sliceName}/deleteSKPProductItem`,
  async ({ skpNumber, id }: { skpNumber: string; id: number }, { dispatch }) => {
    try {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: true }))
      await deleteSKPProduct(skpNumber, id)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: false }))
    }
  },
)

export const getSKPAgreements = createAsyncThunk(
  `${sliceName}/getSKPAgreements`,
  async ({ skpNumber, offset }: { skpNumber: string; offset: number }, { dispatch }) => {
    try {
      const { data } = await fetchSKPAgreements(skpNumber, offset)
      dispatch(setSKPAgreements(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getSKPActualClaims = createAsyncThunk(
  `${sliceName}/getSKPActualClaims`,
  async ({ skpNumber, offset }: { skpNumber: string; offset: number }, { dispatch }) => {
    try {
      const { data } = await fetchSKPActualClaims(skpNumber, offset)
      dispatch(setSKPActualClaims(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getSKPReimbursements = createAsyncThunk(
  `${sliceName}/getSKPReimbursements`,
  async ({ skpNumber, offset }: { skpNumber: string; offset: number }, { dispatch }) => {
    try {
      const { data } = await fetchSKPReimbursements(skpNumber, offset)
      dispatch(setSKPReimbursement(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getSKPProofFiles = createAsyncThunk(
  `${sliceName}/getSKPProofFiles`,
  async ({ skpNumber, offset }: { skpNumber: string; offset: number }, { dispatch }) => {
    try {
      const { data } = await fetchSKProofFiles(skpNumber, offset)
      dispatch(setSKPProofFiles(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const approveActualClaim = createAsyncThunk(
  `${sliceName}/approveActualClaim`,
  async ({ skpNumber, isApprove }: { skpNumber: string; isApprove: boolean }, { dispatch }) => {
    try {
      await dispatch(setLoadingState({ key: 'approveActualClaim', isLoading: true }))
      await postApproveActualClaim(skpNumber, isApprove)
      dispatch(setApprovedActualClaimStatus(isApprove))
      toastSuccess(`Actual Claim Approval successfully ${isApprove ? 'approved' : 'cancelled'}`)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'approveActualClaim', isLoading: false }))
    }
  },
)

export const createReimbursementItem = createAsyncThunk(
  `${sliceName}/createReimbursementItem`,
  async (
    { skpNumber, payload }: { skpNumber: string; payload: PostItemReimbursementInterface },
    { dispatch },
  ) => {
    try {
      await dispatch(setLoadingState({ key: 'updateProduct', isLoading: true }))
      await postReimbursementItem(skpNumber, payload)
      toastSuccess('Actual Quantity Claim is successfully added')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: false }))
    }
  },
)

export const uploadFile = createAsyncThunk(
  `${sliceName}/uploadFile`,
  async (
    {
      skpNumber,
      promotionType,
      module,
      data,
    }: { skpNumber: string; module: string; promotionType?: string; data: FormData },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'uploadData', isLoading: true }))
      await postUploadFile({
        skpNumber,
        data,
        module,
        promotionType: promotionType
          ? `/${promotionType.toLowerCase().replaceAll(' ', '%20')}`
          : '',
      })
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'uploadData', isLoading: false }))
    }
  },
)

export const downloadFile = createAsyncThunk(
  `${sliceName}/uploadFile`,
  async (
    {
      skpNumber,
      promotion_type,
      fileName,
      module,
      url,
      type = 'download',
    }: {
      skpNumber?: string
      promotion_type?: string
      fileName: string
      module?: string
      url?: string
      type?: 'download' | 'campaign-download'
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'downloadData', isLoading: true }))
      if (url) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            downloadFileUrl(URL.createObjectURL(blob), fileName)
          })
      } else {
        const data = await fetchDownloadData({
          skpNumber,
          promotionType: promotion_type ? `/${promotion_type?.replaceAll(' ', '%20')}` : '',
          module,
          url,
          type,
        })
        downloadFileBlob({
          data: data.data as never as Blob,
          mimeType: 'application/*',
          fileName,
          isOpenNewTab: false,
        })
      }

      toastSuccess('Success Download File')
    } catch (error) {
      callErrorMsg(error)
      toastFailed('Error Download File')
    } finally {
      dispatch(setLoadingState({ key: 'downloadData', isLoading: false }))
    }
  },
)

export const getVendorList = createAsyncThunk(
  `${sliceName}/getVendorList`,
  async (_, { dispatch }) => {
    try {
      const { data } = await fetchVendors()
      dispatch(setVendorList(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const submitSKP = createAsyncThunk(
  `${sliceName}/submitSKP`,
  async (
    {
      type,
      payload = {} as PostSKPInterface,
      skpNumber = '',
    }: {
      type: string
      payload?: PostSKPInterface | PostInternalSKPInterface
      skpNumber?: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'submitSKP', isLoading: true }))
      switch (type) {
        case 'add':
        case 'add-internal-skp':
          await postSKP(payload)
          toastSuccess('SKP successfully created')
          break
        case 'edit':
        case 'edit-internal-skp':
          await putSKP(payload, skpNumber)
          toastSuccess('SKP successfully updated')
          break
        case 'delete':
          await deleteSKP(skpNumber)
          toastSuccess('SKP successfully deleted')
          break
        case 'duplicate':
        case 'duplicate-internal-skp':
          await duplicateSKP(payload, skpNumber)
          toastSuccess('SKP successfully duplicated')
          break
        case 'archive':
        case 'reopen':
          await putSubmitReopenSKP(type, skpNumber)
          toastSuccess(`SKP ${type} successfully`)
          break
        default:
          break
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'submitSKP', isLoading: false }))
    }
  },
)

export const getPrintSKPData = createAsyncThunk(
  `${sliceName}/getPrintSKPData`,
  async ({ type, skpNumber }: { skpNumber: string; type: string }) => {
    try {
      const response = await Promise.all([
        fetchSKPProducts(skpNumber, 0, ''),
        fetchTnCList(`promotion_type=${type.replaceAll(' ', '%20')}`),
      ])

      return {
        products: response[0].data?.data,
        tnc: response[1].data?.data,
      } as {
        products: SKPProductsInterface[]
        tnc: TermsAndConditionsDataInterface[]
      }
    } catch (error) {
      return callErrorMsg(error)
    }
  },
)

export const updateSKPStatus = createAsyncThunk(
  `${sliceName}/updateSKPStatus`,
  async (
    {
      type,
      skpNumber,
      rejectReason = '',
    }: {
      skpNumber: string
      type: 'submit' | 'approve' | 'reject'
      rejectReason?: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'getSKP', isLoading: true }))
      if (type === 'submit') {
        await submitSKPData(skpNumber)
      } else if (type === 'approve') {
        await approveSKPData(skpNumber)
      } else {
        await rejectSKPData(skpNumber, rejectReason)
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'getSKP', isLoading: false }))
    }
  },
)

export const deleteSKPDoc = createAsyncThunk(
  `${sliceName}/deleteSKPDoc`,
  async (
    {
      id,
      skpNumber,
      module,
    }: {
      id: number
      skpNumber: string
      module: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'downloadData', isLoading: true }))
      await deleteDocument(id, skpNumber, module)
      toastSuccess('File Deleted')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'downloadData', isLoading: false }))
    }
  },
)

export const deleteSKPReimbursement = createAsyncThunk(
  `${sliceName}/deleteSKPReimbursement`,
  async (
    {
      id,
      skpNumber,
    }: {
      id: number
      skpNumber: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: true }))
      await deleteReimbursement(id, skpNumber)
      toastSuccess('Success Edit Actual Claim Item')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: false }))
    }
  },
)

export const updateActualQtyClaim = createAsyncThunk(
  `${sliceName}/updateActualQtyClaim`,
  async (
    {
      id,
      skpNumber,
      qtyClaim,
    }: {
      id: number
      skpNumber: string
      qtyClaim: number
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: true }))
      await putActualQtyClaimItem(id, skpNumber, qtyClaim)
      toastSuccess('Actual Claim Item Updated')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: false }))
    }
  },
)

export const deleteActualQtyClaim = createAsyncThunk(
  `${sliceName}/deleteActualQtyClaim`,
  async (
    {
      id,
      skpNumber,
    }: {
      id: number
      skpNumber: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: true }))
      await deleteActualQtyClaimItem(id, skpNumber)
      toastSuccess('Actual Claim Item Deleted')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingState({ key: 'updateProduct', isLoading: false }))
    }
  },
)
