import { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { SystemIcon, Checkbox, RowCampaignAddManual } from 'components'

function TableCampaignAddManual({ data, stateCollections }) {
  const initCheckedClearAll = false
  const { selectedData } = stateCollections.getter
  const { setSelectedData } = stateCollections.setter
  const { initSelectedData } = stateCollections.initialValue

  const [checkedClearAll, setCheckedClearAll] = useState(initCheckedClearAll)

  useEffect(() => {
    if (selectedData.length > 0) {
      setCheckedClearAll(true)
    } else {
      setCheckedClearAll(false)
    }
  }, [selectedData])

  const handleChangeClear = () => {
    setCheckedClearAll((prev) => {
      const allData = data.map((itemes) => {
        return itemes
      })
      if (selectedData.length > 0) {
        setSelectedData(initSelectedData)
      } else {
        setSelectedData(allData)
      }
      return !prev
    })
  }
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>
              <WrapperCheck>
                <Checkbox checked={checkedClearAll} onChange={handleChangeClear} />
              </WrapperCheck>
            </Th>
            <Th>
              Nama Hub
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>
              Nama Produk
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>
              Harga
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>Stock</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el, idx) => (
            <RowCampaignAddManual
              key={idx}
              data={el}
              stateCollections={stateCollections}
              campaigAddManualData={data}
            />
          ))}
        </tbody>
      </Container>
    </>
  )
}

TableCampaignAddManual.defaultProps = {
  data: [{}],
}

export default memo(TableCampaignAddManual)

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
`

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`
