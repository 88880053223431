import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  InitialStateType,
  SetQeuryType,
  PutMilestoneStatusRequestType,
} from 'features/Promotion/@types/miletstoneList'
import { getMilestoneList, putMilestoneStatus } from 'features/Promotion/services/milestoneList'
import { callErrorMsg } from 'helpers/errorMsg'

export const initialState: InitialStateType = {
  isLoading: false,
  query: {
    page: 0,
    page_size: 20,
    sort_by: null,
    sort_direction: 'DESC',
    search: '',
  },
  milestones: [],
}

export const SLICE_NAME = 'milestoneList'

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload }: PayloadAction<SetQeuryType>) => {
      state.query = { ...state.query, ...payload }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMilestoneList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMilestoneList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchMilestoneList.fulfilled, (state, action) => {
        const res = action.payload

        state.milestones = res.result
        state.isLoading = false
      })
      .addCase(updateMilestoneStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateMilestoneStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateMilestoneStatus.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setIsLoading, setQuery } = slice.actions
export default slice.reducer

export const fetchMilestoneList = createAsyncThunk(
  `${SLICE_NAME}/fetchMilestoneList`,
  async (_, { getState, rejectWithValue }) => {
    const {
      milestoneList: { query },
    } = getState() as StoreStateType

    try {
      const { data } = await getMilestoneList({
        params: { page_index: query.page, page_size: query.page_size, title: query.search },
      })

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateMilestoneStatus = createAsyncThunk(
  `${SLICE_NAME}/fetchMilestoneStatus`,
  async ({ payload }: PutMilestoneStatusRequestType, { rejectWithValue }) => {
    try {
      const { data } = await putMilestoneStatus({
        payload,
      })

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
