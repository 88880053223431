import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  SLICE_NAME,
  fetchGetTanggungRentengToogle,
  fetchPatchUpdateTanggungRentengToogle,
} from './tanggungRentengAsyncThunk'

export type TanggungRentengSliceType = {
  isLoading: boolean
  isActive: boolean
}

const initialState: TanggungRentengSliceType = {
  isLoading: false,
  isActive: false,
}

const tanggungRentengSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<TanggungRentengSliceType>) => {
      builder.addCase(fetchGetTanggungRentengToogle.fulfilled, (state, action) => {
        state.isActive = action.payload.data.isActive
      })
    },
    [fetchGetTanggungRentengToogle, fetchPatchUpdateTanggungRentengToogle],
  ),
})

export const { reset } = tanggungRentengSlice.actions
export default tanggungRentengSlice.reducer
