import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import {
  getMasterShiftService,
  createMasterShiftService,
  updateMasterShiftService,
} from 'features/Enterprise/HRIS/services'
import {
  setHubList,
  setLoading,
  sliceName,
  setMasterShiftList,
  setDialogState,
} from './masterShiftSlice'

export const initMasterShift = createAsyncThunk(
  `${sliceName}/initMasterShift`,
  (_, { dispatch, getState }) => {
    const {
      filterLocationLists: { locationLists },
    } = getState() as StoreStateType
    dispatch(setHubList(locationLists))
  },
)

export const getMasterList = createAsyncThunk(
  `${sliceName}/getMasterList`,
  async (payload: Parameters<typeof getMasterShiftService>[0], { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: { data },
      } = await getMasterShiftService(payload)
      dispatch(setMasterShiftList(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const saveShift = createAsyncThunk(
  `${sliceName}/saveShift`,
  async (
    payload: Pick<
      Parameters<typeof createMasterShiftService>[0],
      'shiftName' | 'endTime' | 'locationId' | 'startTime'
    > & { id?: number },
    { dispatch, getState },
  ) => {
    const {
      masterShift: { selectedHub, dialogState },
    } = getState() as StoreStateType
    const { id, ...restPayload } = payload
    try {
      dispatch(setLoading(true))
      if (dialogState === 'ADD') {
        await createMasterShiftService({
          ...restPayload,
          isAttachmentRequired: false,
          isCustomTime: false,
          isNoteRequired: false,
          isNormalShift: true,
        })
      } else {
        await updateMasterShiftService(
          {
            ...restPayload,
            isAttachmentRequired: false,
            isCustomTime: false,
            isNoteRequired: false,
            isNormalShift: true,
          },
          id,
        )
      }
      dispatch(getMasterList({ locationId: selectedHub.location_id }))
      dispatch(setDialogState('CLOSE'))
    } catch (error) {
      callErrorMsg(error)
      dispatch(setLoading(false))
    }
  },
)
