import axios from 'axios'
import { LoadingDockType } from './loadingDock'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export type LoadingDockTimeSlotType = {
  id: number
  locationId: number
  loadingDockId: number
  name: string
  startAt: string
  endAt: string
  manpower: number
  manpowerProductivity: number
  timeSlotProductivity: number
  isActive: boolean
  createdBy: string
  remarks: string
}

type TimeSlotType = Pick<LoadingDockType, 'id' | 'type' | 'name' | 'location'> & {
  loadingDockTimeSlot: LoadingDockTimeSlotType[]
}

export type TimeSlotParamType = {
  loadingDockId: number | null
}

export type TimeSlotRequestParamType = Partial<TimeSlotParamType>

export interface GetTimeSlotInterface {
  data: TimeSlotType
  message: string
}

export type UpdateTimeSlotDataType = {
  manpower: number
  manpowerProductivity: number
  isActive: boolean
  remarks: string
}

export const getTimeSlotListApi = (loadingDockId: number, params?: { active?: boolean }) =>
  axios.get<GetTimeSlotInterface>(`${baseUrl}/api/hsm/v1/loading-dock/${loadingDockId}/ldts`, {
    params: params || {},
  })

export const putTimeSlot = (id: number, data: UpdateTimeSlotDataType) =>
  axios.put(`${baseUrl}/api/hsm/v1/loading-dock-time-slot/${id}`, data)

export const putTimeSlotStatus = (id: number, isActive: boolean) =>
  axios.put(`${baseUrl}/api/hsm/v1/loading-dock-time-slot/${id}/status`, { isActive })
