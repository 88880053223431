import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj, queryParamsURL } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'
import {
  GetTimeSlotInterface,
  TimeSlotParamType,
  getTimeSlotListApi,
  putTimeSlot,
  UpdateTimeSlotDataType,
  putTimeSlotStatus,
} from 'features/Enterprise/CapacityHandler/services/timeSlot'

export const SLICE_NAME = 'slotTime'

export const fetchTimeSlotList = createAsyncThunk<GetTimeSlotInterface, undefined, RejectValueType>(
  `${SLICE_NAME}/fetchTimeSlotList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      timeSlot: { query },
    } = getState() as StoreStateType
    const apiQuery = getCleanQueryObj(query) as Partial<TimeSlotParamType>

    try {
      const response = await getTimeSlotListApi(apiQuery.loadingDockId as number)
      queryParamsURL.set(apiQuery)

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const updateTimeSlot = createAsyncThunk(
  `${SLICE_NAME}/updateTimeSlot`,
  async (payload: { id: number; name: string } & UpdateTimeSlotDataType, { dispatch }) => {
    const { id, name, ...data } = payload
    try {
      await putTimeSlot(id, data)
      dispatch(fetchTimeSlotList())
      toastSuccess(`${name} berhasil diatur.`)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const updateTimeSlotStatus = createAsyncThunk(
  `${SLICE_NAME}/updateTimeSlotStatus`,
  async (payload: { id: number; isActive: boolean }, { dispatch }) => {
    const { id, isActive } = payload
    try {
      await putTimeSlotStatus(id, isActive)
      dispatch(fetchTimeSlotList())
      toastSuccess(`Berhasil memgubah status.`)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
