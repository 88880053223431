import axios from 'axios'
import AxiosInstance from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_KONG_API } = process.env

export interface SKPDataInterface {
  actual_claim_skp: string
  approved_actual_claim_at: string | null
  approved_actual_claim_by: string
  budget_skp: string
  campaign_name: string
  end_date: string
  end_date_campaign: string
  id: number
  is_approved_actual_claim: string
  is_campaign_download: string
  is_uploaded_actual_claim: string
  is_uploaded_proof: string
  is_uploaded_signed_skp: string
  is_verified: string
  promotion_type: string
  reject_reason: string
  skp_date: string
  skp_no: string
  start_date: string
  start_date_campaign: string
  status: string
  uploaded_actual_claim_at: string | null
  uploaded_actual_claim_by: string
  vendor_name: string
}

export interface SKPFilesInterface {
  created_at: string
  created_by: string
  created_role: string
  id: number
  nama_document: string
  url_document: string
}

export interface SKPProductsInterface {
  ads_revenue_amount: number
  ads_revenue_cash_gift: string
  barcode: string
  campaign_name: string
  debit_notes: string
  detail_gift_notes: string
  free_product_barcode: string
  free_product_buy: number
  free_product_get_free: number
  free_product_id: number
  free_product_promo_name: string
  free_product_quantity: number
  free_product_sku_name: string
  free_product_uom: string
  freebies_po_number: string
  freebies_total_value: number
  freebies_value_per_uom: number
  id: number
  invoice_number: string
  paid_date: string
  product_id: number
  promotion_quantity: number
  rafaksi_base_discount: string
  rafaksi_price_per_uom: number
  rafaksi_quantity_actual: number
  rafaksi_quantity_max_supported: number
  rafaksi_rebate_actual: number
  rafaksi_rebate_budget: number
  rafaksi_rebate_per_uom: number
  rafaksi_rebate_percentage: number
  remarks: string
  skp_no: string
  sku_name: string
  uom: string
}

export interface SKPDetailsInterface {
  amount: number
  approved_actual_claim_at: string
  approved_actual_claim_by: string
  archived_at: string
  archived_by: string
  astro_pic_role: string
  astro_pic_signed: string
  attachments: string | null
  billing_address: string
  billing_name: string
  billing_npwp: string
  campaign_name: string
  collection_vendor_pic: string
  created_at: string
  created_by: string
  deleted_at: string | null
  deleted_by: string | null
  details: string | null
  end_date: string
  end_date_campaign: string
  id: number
  is_approved_actual_claim: boolean
  is_campaign_download: boolean
  is_uploaded_actual_claim: boolean
  is_uploaded_proof: boolean
  is_uploaded_signed_skp: boolean
  is_verified: boolean
  notes_claim: string
  paid_date: string
  promotion_type: string
  purchase_order: string
  reimbursement_method: string
  reimbursement_number: string
  reject_at: string
  reject_by: string
  reject_reason: string
  reopen_at: string
  reopen_by: string
  skp_no: string
  sourcing_astro_pic: string
  start_date: string
  start_date_campaign: string
  status: string
  submitted_at: string
  submitted_by: string
  top_duration: number
  updated_at: string
  updated_by: string
  uploaded_actual_claim_at: string
  uploaded_actual_claim_by: string
  url_signed_skp: string
  vendor_address: string
  vendor_email: string
  vendor_id: number
  vendor_name: string
  vendor_phone_number: string
  vendor_pic_finance: string
  vendor_pic_finance_phone: string
  vendor_pic_role: string
  vendor_pic_signed: string
  verified_at: string
  verified_by: string
  budget_type: string
}

export interface SKPReimbursementInterface {
  amount: number
  created_at: string
  created_by: string
  deleted_at: string | null
  deleted_by: string | null
  id: number
  number: string
  paid_date: string
  skp: SKPDetailsInterface
  skp_id: number
  type: string
  updated_at: string
  updated_by: string
}

export interface SKPProductDetailsInterface {
  barcode: string
  product_name: string
  uom: string
}

export interface SKPDataQueryInterface {
  limit: number
  offset: number
  total: number
}

export interface SKPProductPayloadInterface {
  id?: number
  barcode?: string
  product_id?: number
  promotion_quantity?: number
  rafaksi_base_discount?: string
  rafaksi_price_per_uom?: number
  rafaksi_rebate_budget?: number
  rafaksi_rebate_per_uom?: number
  rafaksi_rebate_percentage?: number
  remarks?: string
  sku_name?: string
  uom?: string
}

export interface GetSKPResponseInterface {
  data: SKPDataInterface[]
  query: SKPDataQueryInterface
}

export interface GetSKPFilesResponseInterface {
  data: SKPFilesInterface[]
  query: SKPDataQueryInterface
}

export interface GetSKPProductsResponseInterface {
  data: SKPProductsInterface[]
  query: SKPDataQueryInterface
}

export interface GetSKPReimbursementInterface {
  data: SKPReimbursementInterface[]
  query: SKPDataQueryInterface
}

export interface PostSKPProductInterface {
  data: {
    barcode: string
    product_name: string
    uom: string
  }
}

export interface PostItemReimbursementInterface {
  amount: number
  number: string
  paid_date: string
  type: string
}

export interface SKPVendorInterface {
  id: number
  company_name: string
}

export interface FetchVendorsInterface {
  data: SKPVendorInterface[]
}

export interface PostSKPInterface {
  id?: number
  astro_pic_role: string
  astro_pic_signed: string
  billing_address: string
  billing_name: string
  billing_npwp: string
  campaign_name: string
  collection_vendor_pic: string
  end_date: string
  end_date_campaign: string
  notes_claim: string
  promotion_type: string
  reimbursement_method: string
  sourcing_astro_pic: string
  start_date: string
  start_date_campaign: string
  top_duration: number
  vendor_address: string
  vendor_email?: string
  vendor_id?: number
  vendor_name?: string
  vendor_phone_number: string
  vendor_pic_finance: string
  vendor_pic_finance_phone: string
  vendor_pic_role: string
  vendor_pic_signed: string
}

export interface PostInternalSKPInterface {
  id?: number
  campaign_name: string
  promotion_type: string
  vendor_id?: number
  vendor_name?: string
  end_date: string
  end_date_campaign: string
  start_date: string
  start_date_campaign: string
  notes_claim: string
}

export const fetchSKPList = (query: string) =>
  AxiosInstance.Get<GetSKPResponseInterface, true>({
    url: `/enterprise/skp/skp?${query}`,
  })

export const fetchSKPDetails = (skp_no: string) =>
  AxiosInstance.Get<SKPDetailsInterface, true>({
    url: `/enterprise/skp/skp/${skp_no}?state=default`,
  })

export const fetchSKPProducts = (skpNumber: string, offset: number, limit: string) =>
  AxiosInstance.Get<GetSKPProductsResponseInterface, true>({
    url: `/enterprise/skp/skp-detail/${skpNumber}?limit=${limit}&offset=${offset}`,
  })

export const fetchSKPProductDetails = (id: string) =>
  AxiosInstance.Get<SKPProductDetailsInterface, true>({
    url: `/enterprise/skp/products/${id}?state=default`,
  })

export const postSKPProduct = (id: string, data: SKPProductPayloadInterface) =>
  AxiosInstance.Post<PostSKPProductInterface, true>({
    url: `/enterprise/skp/skp-detail/${id}?state=default`,
    data,
  })

export const putSKPProduct = (id: string, data: SKPProductPayloadInterface) =>
  AxiosInstance.Put<PostSKPProductInterface, true>({
    url: `/enterprise/skp/skp-detail/${id}/${data.id}`,
    data,
  })

export const deleteSKPProduct = (skpNumber: string, id: number) =>
  AxiosInstance.Delete<PostSKPProductInterface, true>({
    url: `/enterprise/skp/skp-detail/${skpNumber}/${id}`,
  })

export const fetchSKPAgreements = (skpNumber: string, offset: number) =>
  AxiosInstance.Get<GetSKPFilesResponseInterface, true>({
    url: `/enterprise/skp/agreement-file/${skpNumber}?limit=5&offset=${offset}`,
  })

export const fetchSKPActualClaims = (skpNumber: string, offset: number) =>
  AxiosInstance.Get<GetSKPProductsResponseInterface, true>({
    url: `/enterprise/skp/actual-claim/${skpNumber}?limit=5&offset=${offset}`,
  })

export const fetchSKPReimbursements = (skpNumber: string, offset: number) =>
  AxiosInstance.Get<GetSKPReimbursementInterface, true>({
    url: `/enterprise/skp/reimbursement/${skpNumber}?limit=5&offset=${offset}`,
  })

export const fetchSKProofFiles = (skpNumber: string, offset: number) =>
  AxiosInstance.Get<GetSKPFilesResponseInterface, true>({
    url: `/enterprise/skp/proof-file/${skpNumber}?limit=5&offset=${offset}`,
  })

export const postApproveActualClaim = (skpNumber: string, isApprove: boolean) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/actual-claim/${skpNumber}/${isApprove ? 'approve' : 'cancel-approve'}`,
  })

export const postReimbursementItem = (skpNumber: string, data: PostItemReimbursementInterface) =>
  AxiosInstance.Post({
    url: `/enterprise/skp/reimbursement/${skpNumber}?state=default`,
    data,
  })

export const postUploadFile = ({
  skpNumber,
  module,
  data,
  promotionType = '',
}: {
  skpNumber: string
  module: string
  data: FormData
  promotionType?: string
}) =>
  AxiosInstance.Post<string>({
    url: `/enterprise/skp/${module}/${skpNumber}/upload${promotionType}`,
    data,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    },
  })

// To-DO change method to AxiosInstance
export const fetchDownloadData = ({
  skpNumber,
  promotionType,
  module,
  url,
  type,
}: {
  skpNumber?: string
  promotionType?: string
  module?: string
  url?: string
  type?: 'download' | 'campaign-download'
}) =>
  axios.get(
    url ||
      `${REACT_APP_BASE_URL_KONG_API}/enterprise/skp/${module}/${skpNumber}/${type}${promotionType}`,
    { responseType: 'blob' },
  )

// export const fetchDownloadData = (skpNumber: string, promotion_type: string) =>
//   AxiosInstance.Get({
//     url: `/enterprise/skp/skp-detail/${skpNumber}/download/${promotion_type}`,
//     config: {
//       responseType: 'blob',
//     },
//   })

export const fetchVendors = () =>
  AxiosInstance.Get<FetchVendorsInterface, true>({
    url: '/enterprise/skp/vendors',
  })

export const postSKP = (data: PostSKPInterface | PostInternalSKPInterface) =>
  AxiosInstance.Post({
    url: '/enterprise/skp/skp?state=default',
    data,
  })

export const putSKP = (data: PostSKPInterface | PostInternalSKPInterface, skpNo: string) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/skp/${skpNo}?state=default`,
    data,
  })

export const deleteSKP = (skpNo: string) =>
  AxiosInstance.Delete({
    url: `/enterprise/skp/skp/${skpNo}?state=default`,
  })

export const duplicateSKP = (data: PostSKPInterface | PostInternalSKPInterface, skpNo: string) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/skp/${skpNo}/copy?state=default`,
    data,
  })

export const putSubmitReopenSKP = (type: string, skpNo: string) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/skp/${skpNo}/${type}`,
  })

export const submitSKPData = (skpNo: string) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/skp/${skpNo}/submit`,
  })

export const approveSKPData = (skpNo: string) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/skp/${skpNo}/approve`,
  })

export const rejectSKPData = (skpNo: string, reject_reason: string) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/skp/${skpNo}/reject`,
    data: { reject_reason },
  })

export const deleteDocument = (id: number, skpNo: string, module: string) =>
  AxiosInstance.Delete({
    url: `/enterprise/skp/${module}/${skpNo}/${id}`,
  })

export const deleteReimbursement = (id: number, skpNo: string) =>
  AxiosInstance.Delete({
    url: `/enterprise/skp/reimbursement/${skpNo}/${id}`,
  })

export const putActualQtyClaimItem = (id: number, skpNo: string, rafaksi_quantity_actual: number) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/actual-claim/${skpNo}/${id}`,
    data: { rafaksi_quantity_actual },
  })

export const deleteActualQtyClaimItem = (id: number, skpNo: string) =>
  AxiosInstance.Put({
    url: `/enterprise/skp/actual-claim/${skpNo}/delete/${id}`,
  })
