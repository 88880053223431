import { useState, useEffect, forwardRef, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { TextBody, SystemIcon } from 'components'

const S_FOOTER_HEIGHT = '80x'

type PopupTemplatePropsType = PropsWithChildren<{
  withHeader?: boolean
  isShow: boolean
  handleClose: () => void
  title: ReactNode
  withFooter?: boolean
  footer?: ReactNode
  maxWidth?: string
}>

const PopupTemplate = forwardRef<HTMLDivElement, PopupTemplatePropsType>(
  (
    {
      withHeader = true,
      isShow = false,
      handleClose,
      title = 'Default title',
      children,
      withFooter = false,
      footer,
      maxWidth = '750px',
    },
    ref,
  ) => {
    const [isHide, setIsHide] = useState(isShow)
    const [animation, setAnimation] = useState(false)

    useEffect(() => {
      if (isShow) {
        setIsHide(isShow)
        setTimeout(() => {
          setAnimation(isShow)
        }, 100)
      } else {
        setAnimation(isShow)
        setTimeout(() => {
          setIsHide(isShow)
        }, 300)
      }
    }, [isShow])

    if (!isHide) {
      return null
    }

    return (
      <SContainer ref={ref} isShow={animation}>
        <SContent>
          <SMain style={{ maxWidth }}>
            {withHeader && (
              <SHeaders>
                <TextBody size="headerBigger">{title}</TextBody>
                <SIconWrapper onClick={() => handleClose()}>
                  <SystemIcon iconName="plus" fontSize="headerBigger" colorIcon="text" />
                </SIconWrapper>
              </SHeaders>
            )}
            <SBody>{children}</SBody>
            {withFooter && <SFooter>{footer}</SFooter>}
          </SMain>
        </SContent>
      </SContainer>
    )
  },
)

PopupTemplate.displayName = 'PopupTemplate'

export default PopupTemplate

const SBody = styled.div`
  padding: 16px 32px;
`
const SFooter = styled.div`
  padding: 16px 32px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid ${({ theme: { colors } }) => colors.border};
  background: ${({ theme: { colors } }) => colors.white};
  height: ${S_FOOTER_HEIGHT};
`

const SIconWrapper = styled.div`
  transform: rotate(45deg);
  cursor: pointer;
`

const SHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eff3f9;
  padding: 16px 32px;
`

const SMain = styled.div`
  height: 100vh;
  overflow: scroll;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  margin-left: auto;
  position: relative;
  ::-webkit-scrollbar {
    height: 0;
  }
`

const SContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors.blackFaded};
`

const SContainer = styled.div`
  transition: all ease 0.2s;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  margin: auto;
  transform: ${({ isShow }: { isShow: boolean }) => (isShow ? 'scale(1)' : 'scale(0)')};
  opacity: ${({ isShow }: { isShow: boolean }) => (isShow ? '1' : '0')};
`
