/* eslint-disabled */
import axios from 'axios'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export type LocationType = {
  location_id: number
  location_name: string
}

export type GetLocationsType = {
  content: LocationType[]
}

export type TipeType = 'INBOUND' | 'OUTBOUND'

export type LoadingDockType = {
  id: number
  type: TipeType
  name: string
  location: {
    locationId: number
    locationName: string
    locationType: 'WAREHOUSE'
  }
  createdAt: number
  createdBy: string
  updatedAt: number | null
  updatedBy: string | null
}

export type LoadingDockParamsType = {
  type: TipeType | ''
  locationId: number | null
}

export type LoadingDockRequestParamType = Partial<LoadingDockParamsType>

export interface GetLoadingDockInterface {
  data: LoadingDockType[]
}

export type PostLoadingDockType = {
  locationId: number
  name: string
  type: string
}

export const getLoadingDockListApi = (params: LoadingDockRequestParamType) =>
  axios.get<GetLoadingDockInterface>(`${baseUrl}/api/hsm/v1/loading-dock`, {
    params,
  })

export const getLocations = (name: string) =>
  axios.get<GetLocationsType>(`${baseUrl}/api/location`, { params: { name } })

export const postLoadingDock = (data: PostLoadingDockType) =>
  axios.post(`${baseUrl}/api/hsm/v1/loading-dock`, data)

export const deleteLoadingDock = (id: number) =>
  axios.delete(`${baseUrl}/api/hsm/v1/loading-dock/${id}`)
