import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getDynamicChannel,
  getDynamicChannelSection,
  getDynamicChannelVariant,
  getDynamicChannelCustomLogic,
  postDynamicChannel,
  putDynamicChannel,
  getDynamicChannelById,
  postDynamicChannelActiveStatus,
  deleteDynamicChannel,
  type PutPostDynamicChannelPayloadType,
  type LocationType,
  type CategoryType,
  type GetDynamicChannelByIdResponseType,
  type PostDynamicChannelActiveStatusType,
} from 'utils/apiList/dynamicChannel'
import { getCatalogs, getCategories, getOrderLocation } from 'utils/api'
import { getBannerList } from 'features/CategoryAndPosisi/services/dynamicChannel'

import { callErrorMsg } from 'helpers/errorMsg'

import { toastSuccess } from 'utils/toast'
import { type DynamicChannelStateInterface } from './slice'

export type {
  PutPostDynamicChannelPayloadType,
  LocationType,
  CategoryType,
  GetDynamicChannelByIdResponseType,
}

export const SLICE_NAME = 'dynamicChannel'

export const fetchDynamicChannel = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicChannel`,
  async (
    initialQuery: Partial<DynamicChannelStateInterface['pagination']>,
    { rejectWithValue, getState },
  ) => {
    const rootState = getState() as StoreStateType

    const { pagination, query } = rootState.dynamicChannel

    const finalQuery = {
      ...pagination,
      ...query,
      ...initialQuery,
    }

    const params = {
      page_size: finalQuery.pageSize,
      page_number: finalQuery.pageNumber,
    }

    try {
      const { data } = await getDynamicChannel(params)

      return data.data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar dynamic channel')
      return rejectWithValue(err)
    }
  },
)

export const fetchDynamicChannelSection = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicChannelSection`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getDynamicChannelSection()

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar channel section ')
      return rejectWithValue(err)
    }
  },
)

export const fetchDynamicChannelVariant = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicChannelVariant`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getDynamicChannelVariant()

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar channel type')
      return rejectWithValue(err)
    }
  },
)

export const fetchHub = createAsyncThunk(
  `${SLICE_NAME}/fetchHub`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getOrderLocation()

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar hub')
      return rejectWithValue(err)
    }
  },
)

export const fetchCatalogue = createAsyncThunk(
  `${SLICE_NAME}/fetchCatalogue`,
  async (queryObj: { name?: string }, { rejectWithValue }) => {
    try {
      const { data } = await getCatalogs(queryObj)

      return data?.content || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar katalog')
      return rejectWithValue(err)
    }
  },
)

export const fetchCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchCategory`,
  async (queryObj: { name?: string }, { rejectWithValue }) => {
    try {
      const { data } = await getCategories(queryObj)

      return data?.content || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar kategori')
      return rejectWithValue(err)
    }
  },
)

export const fetchDynamicChannelCustomLogic = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicChannelCustomLogic`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getDynamicChannelCustomLogic()

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar Logic')
      return rejectWithValue(err)
    }
  },
)

export const saveDynamicChannel = createAsyncThunk(
  `${SLICE_NAME}/saveDynamicChannel`,
  async (
    {
      payload,
      isEdit,
      id,
    }: { payload: PutPostDynamicChannelPayloadType; isEdit: boolean; id?: number },
    { rejectWithValue },
  ) => {
    try {
      const { data } = isEdit
        ? await putDynamicChannel(id as number, payload)
        : await postDynamicChannel(payload)

      toastSuccess(
        isEdit
          ? `Berhasil mengedit Dynamic Channel "${payload.title}"`
          : `Berhasil membuat Dynamic Channel "${payload.title}"`,
      )

      return data
    } catch (err) {
      callErrorMsg(
        err,
        isEdit ? 'Gagal mengedit dynamic channel' : 'Gagal menyimpan dynamic channel',
      )
      return rejectWithValue(err)
    }
  },
)

export const fetchDynamicChannelDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicChannelDetail`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await getDynamicChannelById(id)

      return data.data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan detail dynamic channel')
      return rejectWithValue(err)
    }
  },
)

export const updateDynamicChannelActiveStatus = createAsyncThunk(
  `${SLICE_NAME}/updateDynamicChannelActiveStatus`,
  async (payload: PostDynamicChannelActiveStatusType, { rejectWithValue }) => {
    try {
      const { data } = await postDynamicChannelActiveStatus(payload)

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal update status dynamic channel')
      return rejectWithValue(err)
    }
  },
)

export const removeDynamicChannel = createAsyncThunk(
  `${SLICE_NAME}/removeDynamicChannel`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await deleteDynamicChannel(id)

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal menghapus dynamic channel')
      return rejectWithValue(err)
    }
  },
)

export const fetchBannerList = createAsyncThunk(
  `${SLICE_NAME}/fetchBannerList`,
  async ({ name }: { name: string }, { rejectWithValue }) => {
    try {
      const { data } = await getBannerList({ params: { name, pageSize: 60 } })

      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
