import { createAsyncThunk } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  GetTanggungRentengToogleResponseType,
  PatchUpdateTanggungRentengToogleRequestType,
  PatchUpdateTanggungRentengToogleResponseType,
  getTanggungRentengToogleAPI,
  patchUpdateTanggungRentengToogleAPI,
} from 'utils/apiList/tanggungRenteng'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'tanggungRenteng'

export const fetchGetTanggungRentengToogle = createAsyncThunk<
  GetTanggungRentengToogleResponseType,
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchGetTanggungRentengToogle`, async (_, { rejectWithValue }) => {
  try {
    const response = await getTanggungRentengToogleAPI()

    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPatchUpdateTanggungRentengToogle = createAsyncThunk<
  PatchUpdateTanggungRentengToogleResponseType,
  PatchUpdateTanggungRentengToogleRequestType,
  RejectValueType
>(
  `${SLICE_NAME}/fetchPatchUpdateTanggungRentengToogle`,
  async ({ isActive }, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchUpdateTanggungRentengToogleAPI({ isActive })
      dispatch(fetchGetTanggungRentengToogle())

      toastSuccess(response.data.message)

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
