import axios from 'axios'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export interface GetMasterShiftServicesInterface {
  data: {
    id: number
    location_id: number
    shift_name: string
    start_time: string
    end_time: string
    is_note_required: boolean
    is_attachment_required: boolean
    is_custom_time: boolean
    is_normal_shift: boolean
    description: string
  }[]
}

interface CreateAndUpdateMasterShiftInterface {
  locationId: number
  shiftName: string
  startTime: string
  endTime: string
  isNoteRequired: boolean
  isAttachmentRequired: boolean
  isCustomTime: boolean
  isNormalShift: boolean
}

export const getMasterShiftService = (params: { locationId?: number }) =>
  axios.get<GetMasterShiftServicesInterface>(`${baseUrl}/api/staff-management/shift/list`, {
    params: {
      ...params,
      isNormalShift: true,
    },
  })

export const createMasterShiftService = (payload: CreateAndUpdateMasterShiftInterface) =>
  axios.post<GetMasterShiftServicesInterface>(`${baseUrl}/api/staff-management/shift`, payload)

export const updateMasterShiftService = (
  payload: CreateAndUpdateMasterShiftInterface,
  id?: number,
) =>
  axios.put<GetMasterShiftServicesInterface>(`${baseUrl}/api/staff-management/shift/${id}`, payload)
