/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Typography } from '@astro-ui/components'
import { memo } from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from 'styled-components'
import dayjs from 'dayjs'

interface SLAInterface {
  label: string
  additional_sla: Nullable<{
    min_duration: Nullable<number>
    max_duration: Nullable<number>
  }>
  orderType?: string
}
interface ColorMappingInterface {
  color: string
  bg: string
}
export const mappingColorTag = (label: string, orderType?: string): ColorMappingInterface => {
  const isSuperOrder = orderType === 'SUPER_V2'
  const tagColors: { [key: string]: ColorMappingInterface } = {
    redTag: { color: '#F94D63', bg: '#FEEDEF' },
    blueTag: { color: '#246EE5', bg: '#E9F1FC' },
    greenTag: { color: '#00AA5B', bg: '#E6F7EF' },
    purpleTag: { color: '#6847BB', bg: '#F0EDF8' },
  }
  const colorMappings: { [key: string]: ColorMappingInterface } = {
    '15 menit': isSuperOrder ? tagColors.redTag : tagColors.blueTag,
    '30 menit': tagColors.redTag,
    '45 menit': tagColors.purpleTag,
    '1 jam': isSuperOrder ? tagColors.blueTag : tagColors.redTag,
    '2 jam': tagColors.greenTag,
    '4 jam': isSuperOrder ? tagColors.purpleTag : tagColors.blueTag,
    'same day': tagColors.greenTag,
  }

  return colorMappings[label?.toLowerCase()] || { color: '#6D7588', bg: '#F3F6FA' }
}
const SLA = ({ label, additional_sla, orderType }: SLAInterface) => {
  const differenceInMinutes = dayjs(additional_sla?.max_duration).diff(
    dayjs(additional_sla?.min_duration),
    'minute',
  )
  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <SLATypography
        hasAdditional={Number(additional_sla?.max_duration) > 0}
        color={mappingColorTag(label, orderType).color}
        backgroundSLA={mappingColorTag(label, orderType).bg}
      >
        {label}
      </SLATypography>
      {Number(additional_sla?.max_duration) > 0 && (
        <AdditionalContainer>
          <Tooltip
            arrow
            title={
              <Box padding="12px">
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '22px',
                    textAlign: 'left',
                  }}
                  color="#FFFFFF"
                >
                  Extra Waktu +{differenceInMinutes} menit
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    textAlign: 'left',
                  }}
                  color="#FFFFFF"
                >
                  Ada tambahan waktu akibat backlog / jumlah besar / kitchen / kondisi eksternal.
                </Typography>
              </Box>
            }
            PopperProps={{
              disablePortal: true,
              placement: 'bottom',
              sx: {
                transition: 'none',
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: '#212121',
                  borderRadius: '8px',

                  '& .MuiTooltip-arrow': {
                    color: '#212121',
                    transition: 'none !important',
                  },
                },
              },
              modifiers: [{ name: 'offset', options: { offset: [-10, -5] } }],
            }}
            sx={{
              transition: 'none',
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#212121',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: 4,
                fontSize: 12,
                padding: '8px 12px',
              },
            }}
          >
            <AdditionalSLA>+{differenceInMinutes}</AdditionalSLA>
          </Tooltip>
        </AdditionalContainer>
      )}
    </Box>
  )
}

type SLATypographyType = {
  backgroundSLA?: string
  hasAdditional?: boolean
}

const SLATypography = styled(Box)<SLATypographyType>`
  padding: 2px 4px;
  line-height: 13.5px;
  border-radius: 3px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  background-color: ${({ backgroundSLA }) => backgroundSLA || ''};
  text-transform: capitalize;
  max-width: fit-content;
  padding-right: ${({ hasAdditional }) => (hasAdditional ? '8px' : '4px')};
`

const AdditionalContainer = styled(Box)`
  border-left: 3px solid #fff;
  border-top-left-radius: 3.5px;
  border-bottom-left-radius: 3.5px;
  margin-left: -5px;
  cursor: pointer;
`

const AdditionalSLA = styled(Box)`
  padding: 2px 6px;
  line-height: 13.5px;
  border-radius: 4px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  background-color: #e9e9e9;
  color: #646464;
  text-transform: capitalize;
  max-width: fit-content;
  font-style: italic;
  font-weight: 700;
`

export default memo(SLA)
