import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'
import { putSkipVerificationDropOff, putSkipVerificationPackage } from 'utils/apiList/allOrder'
import { sliceName, setDialogState, setPopUpSkipDropOff } from './allOrderSlice'

export const onSubmitSkipPackageVerification = createAsyncThunk(
  `${sliceName}/onSubmitSkipPackageVerification`,
  async (
    payload: { captain_email: string; captain_password: string; note: string; orderId: number },
    { dispatch },
  ) => {
    try {
      const { orderId, ...restPayload } = payload
      await putSkipVerificationPackage(restPayload, orderId)
      dispatch(setDialogState('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const onSubmitSkipPackageDropOff = createAsyncThunk(
  `${sliceName}/onSubmitSkipPackageDropOff`,
  async (
    payload: { email: string; password: string; note: string; orderId: number; type: string },
    { dispatch },
  ) => {
    try {
      const { orderId, ...restPayload } = payload
      await putSkipVerificationDropOff(restPayload, orderId)
      dispatch(setPopUpSkipDropOff('CLOSED'))
      toastSuccess('Berhasil Verifikasi Drop Off')
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
