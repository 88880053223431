import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const HomepageSlotting = lazy(() => import('../pages/HomepageSlotting'))
const DynamicChannel = lazy(() => import('../pages/DynamicChannel'))

export const HomepageSlottingRouter: RouterFeatureInterface<
  'HomepageSlottingRouter',
  '/dashboard/homepage-slotting'
> = {
  name: 'HomepageSlottingRouter',
  Component: HomepageSlotting,
  path: '/dashboard/homepage-slotting',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_ADJUSTMENT',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PARTNERSHIP',
    'AUTH_MARKETING',
    'AUTH_HO_MARKETING',
    'AUTH_PRODUCT_MANAGER',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_QUALITY_ENGINEERING',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

export const DynamicChannelRouter: RouterFeatureInterface<
  'DynamicChannelRouterRouter',
  '/dashboard/dynamic-channel'
> = {
  name: 'DynamicChannelRouterRouter',
  Component: DynamicChannel,
  path: '/dashboard/dynamic-channel',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_MERCHANDISING_HUB',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_HO_MERCHANDISING',
  ],
  isDashboard: true,
}

const CategoryAndPositionRouter = [HomepageSlottingRouter, DynamicChannelRouter]

export const CategoryAndPositionRouterDetail = generateRouterDetail(CategoryAndPositionRouter)

export default CategoryAndPositionRouter
