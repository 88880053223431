import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const sliceName = 'allOrder'

export interface AllOrderInitialStateInterface {
  dialogState: 'SKIP_VERIFICATION_PACKAGE_DIALOG' | 'CLOSED'
  selectedOrderDate: ObjectNullType
  popUpSkipDropOff: 'SKIP_VERIFICATION_DROP_OFF' | 'CLOSED'
}

const initialState: AllOrderInitialStateInterface = {
  dialogState: 'CLOSED',
  selectedOrderDate: {},
  popUpSkipDropOff: 'CLOSED',
}

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setDialogState: (
      state,
      action: PayloadAction<AllOrderInitialStateInterface['dialogState']>,
    ) => {
      state.dialogState = action.payload
    },
    setSelectedOrderData: (
      state,
      action: PayloadAction<AllOrderInitialStateInterface['selectedOrderDate']>,
    ) => {
      state.selectedOrderDate = action.payload
    },
    setPopUpSkipDropOff: (
      state,
      action: PayloadAction<AllOrderInitialStateInterface['popUpSkipDropOff']>,
    ) => {
      state.popUpSkipDropOff = action.payload
    },
    setPopUpDone: (
      state,
      action: PayloadAction<AllOrderInitialStateInterface['popUpSkipDropOff']>,
    ) => {
      state.popUpSkipDropOff = action.payload
    },
  },
})

export const { setDialogState, setSelectedOrderData, setPopUpSkipDropOff } = slice.actions

export default slice.reducer
