import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const SLICE_NAME = 'flagManagement'
export type GenericErrorType = {
  status: boolean
  message?: string
  code: number
}
export type FeatureFlagPaginationType = {
  sort: string
  direction: 'ASC' | 'DESC'
  pageSize: number
  pageIndex?: number
  totalElement?: number
  numberOfElements?: number
  totalPages: number
}

export type FeatureFlagType = {
  hubFeatureId: number
  featureKey: string
  active: boolean
  deviceId: number
  deviceName: string
  updatedAt?: Nullable<number> | Nullable<string>
}

export type FeatureFlagResponseType = {
  data: Array<FeatureFlagType>
  pagination: FeatureFlagPaginationType
  error: GenericErrorType
}

export type LocationContentType = {
  locationId: number
  locationName: string
  locationType: string
  updatedAt: number
  active: boolean
}

export type LocationSortType = {
  unsorted: boolean
  sorted: boolean
  empty: boolean
}

export type LocationPaginationType = {
  sort: LocationSortType
  pageSize: number
  pageIndex: number
  numberOfElements: number
  totalElement: number
  totalPages: number
}

export type LocationResponseType = {
  data: Array<LocationContentType>
  pagination: LocationPaginationType
  totalElements: number
  totalPages: number
  last: boolean
  first: boolean
  sort: LocationSortType
  numberOfElements: number
  size: number
  number: number
  empty: boolean
}
export interface FlagManagementStateInterface {
  featureFlags: FeatureFlagResponseType
  locations: LocationResponseType
  isLoading: boolean
}

const initialState: FlagManagementStateInterface = {
  featureFlags: {} as FeatureFlagResponseType,
  locations: {} as LocationResponseType,
  isLoading: false,
}

const flagManagementSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setFeatureFlags: (state, action) => {
      state.featureFlags = action.payload
    },
    setLocations: (state, action: PayloadAction<LocationResponseType>) => {
      if (action.payload.pagination.pageIndex >= 1) {
        if (state.locations.data.length === action.payload.pagination.totalElement) {
          return
        }
        state.locations = {
          ...action.payload,
          data: [...state.locations.data, ...action.payload.data],
        }
        return
      }
      state.locations = action.payload
    },
    reset: () => initialState,
  },
})

export const { reset, setFeatureFlags, setLocations, setLoading } = flagManagementSlice.actions
export default flagManagementSlice.reducer
