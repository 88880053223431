import axios from 'axios'
import apiServiceApiGateway from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export type SupplyOrderApprovalStatusType =
  | 'WAITING_APPROVAL'
  | 'IN_REVIEW'
  | 'COMPLETED'
  | 'WAITING_LP'

export type GetSoApprovalRequestType =
  | Partial<{
      page_index: number
      page_size: number
      supply_order_id: number
      date: string
      created_by: string
      status: string
      approver: 'IC' | 'LP'
    }>
  | undefined

export type GetSoApprovalResponseType = {
  data: {
    id: number
    so_id: number
    so_number: string
    approver: string
    total_item: number
    created_by: string
    status: SupplyOrderApprovalStatusType
    date: string
    origin_location_id: number
    origin_location_name: string
    destination_location_id: number
    destination_location_name: string
  }[]
  pagination: {
    sort: string
    direction: string
    page_size: number
    page_index: number
    number_of_elements: number
    total_elements: number
    total_pages: number
  }
}

export type GetSupplyOrderApprovalDetailRequestType = {
  id: number
}

export type PutSupplyOrderApprovalDetailRequestType = {
  id: number
  payload: {
    UpdateSupplyOrderPartialItemRequestList: {
      soPartialItemId: number
      icQuantity: number
      chronology: string
      icReason: string
      lpTarget: string | null
      evidence: string | null
    }[]
    newsPdf: string | null
  }
}

export type PutSupplyOrderApprovalDetailResponseType = {
  data: {
    message: string
  }
}

export type GetUsersRequestType =
  | Partial<{
      name: string
      pageSize: number
    }>
  | undefined

export type GetUsersResponseType = {
  content: {
    id: number
    full_name: string
  }[]
}

export type GetSoListParamsType = {
  created: boolean
  pageIndex: number
  number?: string
  origin: string
}

export type GetSoListResponseType = {
  data: {
    content: {
      supplyOrderId: number
      supplyOrderNumber: string
    }[]
  }
}

export type SupplyOrderApprovalDetailV2ItemType = {
  createdAt: string
  so_partial_id: number
  so_partial_item_id: number
  so_number: string
  product_sku: string
  product_name: string
  origin: string
  destination: string
  quantity: number
  evidence: string
  chronology: string
  status: string
  createdBy: string
  approver_level: string
  packing_recording: {
    koli_id: number
    koli_num: string
    footage_url: string
    packing_line_num: number
  }
  ic_quantity: number
  ic_evidence: string
  ic_chronology: string
  ic_approver: string
  ic_approver_date: string
  ic_reason: string
  lp_target: string
  lp_evidence: string
  lp_chronology: string
  lp_approver: string
  lp_approver_date: string
  news_pdf: string
}

export const getSoApproval = (params: GetSoApprovalRequestType) =>
  apiServiceApiGateway.Get<GetSoApprovalResponseType>({
    url: `/wms/internal/v1/supply-order-partial`,
    params,
  })

export const getSupplyOrderApprovalDetailV2 = (id: number) =>
  apiServiceApiGateway.Get<SupplyOrderApprovalDetailV2ItemType[]>({
    url: `/wms/internal/v1/supply-order-partial/${id}`,
  })

export const putSupplyOrderApprovalDetail = ({
  id,
  payload,
}: PutSupplyOrderApprovalDetailRequestType) =>
  axios.put(`${baseUrl}/api/supply-order-partial/${id}`, payload)

export const getUsers = (params: GetUsersRequestType) =>
  axios.get(`${baseUrl}/api/product/catalog/pic`, { params })

export const getSoList = (params: GetSoListParamsType) =>
  Get<GetSoListResponseType>({ url: '/api/wms/v2/supply-order', params })
