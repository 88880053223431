import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import {
  getExperiment,
  getExperimentById,
  getExperimentType,
  getTargetChannel,
  postExperiment,
  putExperiment,
  deleteVariantAttributes,
  deleteVariants,
  putStartExperiment,
  putStopExperiment,
  getTestingExperimentById,
  putTestingExperiment,
  putStartTestingExperiment,
  putStopTestingExperiment,
  getUserSegments,
  type GetExperimentParamReqType,
  type GetExperimentByIdResponseType,
  type ExperimentVariantType as APIExperimentVariantType,
  type PostPutExperimentPayloadType,
  type ExperimentVariantAttributeType,
  type ExperimentType,
  PutTestingExperimentPayloadType,
  GetTestingExperimentResponseType,
  type UserSegmentType,
} from 'utils/apiList/abtesting'
import { stringToDateObject } from 'utils/helpers/date'
import { toastSuccess } from 'utils/toast'

type CustomExperimentVariantAttributeType = ExperimentVariantAttributeType & {
  customId: string
  isDeleted: boolean
}

type ExperimentVariantType = Omit<APIExperimentVariantType, 'attributes'> & {
  customId: string
  isDeleted: boolean
  attributes: CustomExperimentVariantAttributeType[]
  isEdit?: boolean
}

export type { GetExperimentByIdResponseType, ExperimentVariantType }

export type TestDeviceModalStateType = 'OPEN' | 'CLOSE'

type TargetUserType = 'ALL_USER' | 'SEGMENT_USER'

export const targetUserOptions: { name: string; value: TargetUserType }[] = [
  { name: 'All User', value: 'ALL_USER' },
  { name: 'Segment User', value: 'SEGMENT_USER' },
]

export const uploadUserABServiceCode = 'PLATFORM_AB_TESTING_UPLOAD_USER'

export const getInitialVariantData = (idx: number, experimentId?: number, isEdit = false) => ({
  name: idx > 0 ? `Variant ${idx}` : 'Baseline',
  experiment_id: experimentId,
  target_user: 0,
  total_user: 0,
  id: undefined,
  customId: crypto.randomUUID(),
  isDeleted: false,
  attributes: [],
  isEdit,
})

export const getInitialVariantAttributeData = (varianttId?: number) => ({
  customId: crypto.randomUUID(),
  isDeleted: false,
  experiment_variant_id: varianttId,
  attribute_key: '',
  attribute_value: '',
  id: undefined,
})

export interface ABAdminCreateEditStateInterface {
  isLoading: boolean
  form: {
    name: string
    targetChannel: string
    type: string
    targetUser: string
    dateRange: [Date | null, Date | null]
    variants: ExperimentVariantType[]
    is_exclusive: boolean
    segment: Nullable<UserSegmentType>
  }
  currentEditingData: {
    experiment: ExperimentType
    variants: ExperimentVariantType[]
  } | null
  costants: {
    experimentTypeList: string[]
    experimentChannelLit: string[]
  }
  showModalVariantAddEdit: boolean
  currentEditingVariantData: ExperimentVariantType
  testDeviceModalState: TestDeviceModalStateType
  testingExperimentDetail: Nullable<GetTestingExperimentResponseType>
  selectedTargetUser: (typeof targetUserOptions)[0]
  userSegmentList: UserSegmentType[]
}

const SLICE_NAME = 'abAdminCreateEdit'

export const initialState: ABAdminCreateEditStateInterface = {
  isLoading: false,
  form: {
    name: '',
    targetChannel: '',
    type: '',
    targetUser: '',
    dateRange: [null, null],
    variants: [],
    is_exclusive: false,
    segment: null,
  },
  currentEditingData: null,
  costants: {
    experimentTypeList: [],
    experimentChannelLit: [],
  },
  // PopupAddEditVariant
  showModalVariantAddEdit: false,
  currentEditingVariantData: getInitialVariantData(0),
  testDeviceModalState: 'CLOSE',
  testingExperimentDetail: null,
  selectedTargetUser: targetUserOptions[0],
  userSegmentList: [],
}

const abAdminCreateEdit = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setForm: (
      state,
      action: PayloadAction<{
        key: keyof ABAdminCreateEditStateInterface['form']
        value: unknown
      }>,
    ) => {
      const { key, value } = action.payload

      state.form = {
        ...state.form,
        [key]: value,
      }
    },
    setShowModalVariantAddEdit: (state, action: PayloadAction<boolean>) => {
      state.showModalVariantAddEdit = action.payload
    },
    setCurrentEditingVariantData: (state, action: PayloadAction<ExperimentVariantType>) => {
      state.currentEditingVariantData = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setModalTestDeviceState: (state, { payload }: PayloadAction<TestDeviceModalStateType>) => {
      state.testDeviceModalState = payload
    },
    setSelectedTargetUser: (
      state,
      { payload }: PayloadAction<ABAdminCreateEditStateInterface['selectedTargetUser']>,
    ) => {
      state.selectedTargetUser = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExperiment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchExperiment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchExperiment.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchExperimentById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchExperimentById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchExperimentById.fulfilled, (state, action) => {
        state.isLoading = false

        const { experiment, variants } = action.payload

        const transformedVariant = variants.map((variant) => ({
          ...variant,
          isDeleted: false,
          customId: crypto.randomUUID(),
          attributes: variant.attributes.map((attr) => ({
            ...attr,
            isDeleted: false,
            customId: crypto.randomUUID(),
          })),
        }))

        state.currentEditingData = {
          experiment,
          variants: transformedVariant,
        }

        state.form = {
          name: experiment.name,
          targetChannel: experiment.target_channel,
          type: experiment.type,
          targetUser: experiment.upload_id ? '100' : String(experiment.target_user),
          dateRange: [
            stringToDateObject(experiment.start_date, 'YYYY-MM-DD hh:mm::ss Z'),
            stringToDateObject(experiment.end_date, 'YYYY-MM-DD hh:mm::ss Z'),
          ],
          variants: transformedVariant,
          is_exclusive: experiment.is_exclusive,
          segment: state.form.segment || null,
        }

        const [allUser, segmentUser] = targetUserOptions

        if (experiment.segment_id && experiment.upload_id === 0) {
          state.selectedTargetUser = segmentUser
        } else {
          state.selectedTargetUser = allUser
        }
      })
      .addCase(fetchExperimentType.fulfilled, (state, action) => {
        state.costants.experimentTypeList = action.payload
      })
      .addCase(fetchTargetChannel.fulfilled, (state, action) => {
        state.costants.experimentChannelLit = action.payload
      })
      .addCase(createExperiment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createExperiment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createExperiment.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateExperiment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateExperiment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateExperiment.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(runExperiment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(runExperiment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(runExperiment.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(stopExperiment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(stopExperiment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(stopExperiment.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchTestingExperimentById.fulfilled, (state, { payload }) => {
        state.testingExperimentDetail = payload
      })
      .addCase(fetchUserSegment.fulfilled, (state, { payload }) => {
        state.userSegmentList = payload
      })
  },
})

export const {
  reset,
  setForm,
  setShowModalVariantAddEdit,
  setCurrentEditingVariantData,
  setIsLoading,
  setModalTestDeviceState,
  setSelectedTargetUser,
} = abAdminCreateEdit.actions
export default abAdminCreateEdit.reducer

export const fetchExperiment = createAsyncThunk(
  `${SLICE_NAME}/fetchExperiment`,
  async (params: GetExperimentParamReqType, { rejectWithValue }) => {
    try {
      const { data } = await getExperiment(params)

      return data?.data || {}
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list eksperimen')
      return rejectWithValue(err)
    }
  },
)

export const fetchExperimentById = createAsyncThunk(
  `${SLICE_NAME}/fetchExperimentById`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await getExperimentById(id)

      return data?.data || {}
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan data experiment')
      return rejectWithValue(err)
    }
  },
)

export const fetchExperimentType = createAsyncThunk(
  `${SLICE_NAME}/fetchExperimentType`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getExperimentType()

      return data?.data?.types || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar tipe eksperimen')
      return rejectWithValue(err)
    }
  },
)

export const fetchTargetChannel = createAsyncThunk(
  `${SLICE_NAME}/fetchTargetChannel`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getTargetChannel()

      return data?.data?.target_channels || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar kanal eksperimen')
      return rejectWithValue(err)
    }
  },
)

export const createExperiment = createAsyncThunk(
  `${SLICE_NAME}/createExperiment`,
  async (payload: PostPutExperimentPayloadType, { rejectWithValue }) => {
    try {
      await postExperiment(payload)

      toastSuccess('Membuat eksperimen berhasil')

      return null
    } catch (err) {
      callErrorMsg(err, 'Gagal membuat eksperimen')
      return rejectWithValue(err)
    }
  },
)

export const updateExperiment = createAsyncThunk(
  `${SLICE_NAME}/updateExperiment`,
  async (payload: PostPutExperimentPayloadType, { rejectWithValue }) => {
    try {
      await putExperiment(payload.id as number, payload)

      toastSuccess('Update eksperimen berhasil')

      return null
    } catch (err) {
      callErrorMsg(err, 'Gagal membuat eksperimen')
      return rejectWithValue(err)
    }
  },
)

export const removeVariantAttributes = createAsyncThunk(
  `${SLICE_NAME}/removeVariantAttributes`,
  async (payload: number[], { rejectWithValue }) => {
    try {
      await deleteVariantAttributes(payload)

      return null
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const removeVariants = createAsyncThunk(
  `${SLICE_NAME}/removeVariants`,
  async (payload: number[], { rejectWithValue }) => {
    try {
      await deleteVariants(payload)

      return null
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const runExperiment = createAsyncThunk(
  `${SLICE_NAME}/runExperiment`,
  async (payload: number, { rejectWithValue }) => {
    try {
      await putStartExperiment(payload)

      toastSuccess('Eksperimen berhasil dijalankan')

      return null
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const stopExperiment = createAsyncThunk(
  `${SLICE_NAME}/stopExperiment`,
  async (payload: number, { rejectWithValue }) => {
    try {
      await putStopExperiment(payload)

      toastSuccess('Eksperimen berhasil dihentikan')

      return null
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchTestingExperimentById = createAsyncThunk(
  `${SLICE_NAME}/fetchTestingExperimentById`,
  async (id: number) => {
    try {
      const { data } = await getTestingExperimentById(id)

      return data.data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan data testing experiment')
      return null
    }
  },
)

export const updateTestingExperiment = createAsyncThunk(
  `${SLICE_NAME}/updateTestingExperiment`,
  async (
    {
      experimentId,
      payload,
      callback,
    }: {
      experimentId: number
      payload: PutTestingExperimentPayloadType
      callback?: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await putTestingExperiment(experimentId, payload)

      if (typeof callback === 'function') {
        callback()
      }

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal Update Testing Eksperimen')
      return rejectWithValue(err)
    }
  },
)

export const fetchUserSegment = createAsyncThunk(
  `${SLICE_NAME}/fetchUserSegment`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserSegments()

      return data.data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar user segemnt')
      return rejectWithValue(err)
    }
  },
)

export const startTestingExperiment = createAsyncThunk(
  `${SLICE_NAME}/startTestingExperiment`,
  async (experimentId: number) => {
    try {
      await putStartTestingExperiment(experimentId)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const stopTestingExperiment = createAsyncThunk(
  `${SLICE_NAME}/stopTestingExperiment`,
  async (experimentId: number) => {
    try {
      await putStopTestingExperiment(experimentId)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)
