import { Get, Patch } from 'config/apiServiceConfig'

export type GetTanggungRentengToogleResponseType = {
  data: {
    isActive: boolean
  }
}

export type PatchUpdateTanggungRentengToogleRequestType = {
  isActive: boolean
}

export type PatchUpdateTanggungRentengToogleResponseType = {
  message: string
}

export const getTanggungRentengToogleAPI = () =>
  Get<GetTanggungRentengToogleResponseType>({
    url: `/api/ims/v1/tanggung-renteng/toggle`,
  })

export const patchUpdateTanggungRentengToogleAPI = (
  payload: PatchUpdateTanggungRentengToogleRequestType,
) =>
  Patch<PatchUpdateTanggungRentengToogleResponseType>({
    url: `/api/ims/v1/tanggung-renteng/toggle`,
    data: payload,
  })
