import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { Get, Post, Put, Delete } = axiosInstanceApiGateway

export type ExperimentType = {
  target_channel: string
  status: 'Draft' | 'Running' | 'Scheduled' | 'In Progress' | 'Ended' | 'Testing'
  /**
   * Date string
   */
  end_date: string
  /**
   * Date string
   */
  start_date: string
  /**
   * Date string
   */
  updated_at: string
  /**
   * Date string
   */
  created_at: string
  updated_by: string
  type: string
  id: number
  target_user: number
  name: string
  is_deleted: boolean
  total_user: number
  created_by: string
  upload_id: number
  is_exclusive: boolean
  segment_id: number
}

export type GetExperimentResponseType = {
  data: {
    experiments: ExperimentType[]
  }
}

export type GetExperimentParamReqType = Partial<{
  direction?: 'acs' | 'desc'
  name?: string
  page_index: number
  page_size: number
  /**
   * sort by column ex: id, name, target_user
   */
  sort?: string
}>

export type ExperimentVariantAttributeType = {
  experiment_variant_id?: number
  attribute_key: string
  attribute_value: string
  id?: number
}

export type ExperimentVariantType = {
  name: string
  experiment_id?: number
  /**
   * value by portion weight int
   */
  target_user: number
  total_user: number
  id?: number
  attributes: ExperimentVariantAttributeType[]
}

export type GetExperimentByIdResponseType = {
  data: {
    experiment: ExperimentType
    variants: ExperimentVariantType[]
  }
}

type GetExperimentTypeResponseType = {
  data: {
    types: string[]
  }
}

type GetTargetChannelResponseType = {
  data: {
    target_channels: string[]
  }
}

export type PostPutExperimentPayloadType = {
  id?: number
  name: string
  /**
   * string miliseconds
   */
  start_date: string
  /**
   * string miliseconds
   */
  end_date: string
  target_channel: string
  target_user: string
  segment_id: number
  type: string
  variants: ExperimentVariantType[]
  is_exclusive: boolean
}

export type UserSegmentType = {
  total_user: number
  name: string
  id: number
}

export type TestingUserType = {
  user_id: number
  variant_id: number
  is_deleted: boolean
}

export type GetTestingExperimentResponseType = {
  id: number
  status: ExperimentType['status']
  variants: Required<Pick<ExperimentVariantType, 'id' | 'name'>>[]
  testing_users: TestingUserType[]
}

export type PutTestingExperimentPayloadType = {
  testing_users: TestingUserType[]
}

export const getExperiment = (params?: GetExperimentParamReqType) =>
  Get<GetExperimentResponseType, true>({
    url: '/abtesting/internal/v1/experiment',
    params,
  })

export const getExperimentById = (experimentId: number) =>
  Get<GetExperimentByIdResponseType, true>({
    url: '/abtesting/internal/v1/experiment/:experimentId',
    urlParams: { experimentId },
  })

export const getExperimentType = () =>
  Get<GetExperimentTypeResponseType, true>({
    url: '/abtesting/internal/v1/experiment/type',
  })

export const getTargetChannel = () =>
  Get<GetTargetChannelResponseType, true>({
    url: '/abtesting/internal/v1/experiment/target_channel',
  })

export const postExperiment = (payload: PostPutExperimentPayloadType) =>
  Post({
    url: '/abtesting/internal/v1/experiment',
    data: payload,
  })

export const putExperiment = (experimentId: number, payload: PostPutExperimentPayloadType) =>
  Put({
    url: '/abtesting/internal/v1/experiment/:experimentId',
    urlParams: {
      experimentId,
    },
    data: payload,
  })

export const deleteVariantAttributes = (variantAttributeIds: number[]) => {
  const params = new URLSearchParams()

  params.append('is_deleted', String(true))

  variantAttributeIds.forEach((id) => {
    params.append('ids', String(id))
  })

  return Delete({
    url: `/abtesting/internal/v1/experiment/variant/attributes`,
    params,
  })
}

export const deleteVariants = (variantIds: number[]) => {
  const params = new URLSearchParams()

  params.append('is_deleted', String(true))

  variantIds.forEach((id) => {
    params.append('ids', String(id))
  })

  return Delete({
    url: `/abtesting/internal/v1/experiment/variants`,
    params,
  })
}

export const putStartExperiment = (experimentId: number) =>
  Put({
    url: '/abtesting/internal/v1/experiment/:experimentId/start',
    urlParams: {
      experimentId,
    },
  })

export const putStopExperiment = (experimentId: number) =>
  Put({
    url: '/abtesting/internal/v1/experiment/:experimentId/stop',
    urlParams: {
      experimentId,
    },
  })

export const getTestingExperimentById = (experimentId: number) =>
  Get<GetTestingExperimentResponseType>({
    url: '/abtesting/internal/v1/experiment/testing/:experimentId',
    urlParams: { experimentId },
  })

export const putTestingExperiment = (experimentId: number, data: PutTestingExperimentPayloadType) =>
  Put({
    url: '/abtesting/internal/v1/experiment/testing/:experimentId',
    urlParams: { experimentId },
    data,
  })

export const putStartTestingExperiment = (experimentId: number) =>
  Put({
    url: '/abtesting/internal/v1/experiment/testing/:experimentId/start',
    urlParams: {
      experimentId,
    },
  })

export const putStopTestingExperiment = (experimentId: number) =>
  Put({
    url: '/abtesting/internal/v1/experiment/testing/:experimentId/stop',
    urlParams: {
      experimentId,
    },
  })

export const getUserSegments = () =>
  Get<UserSegmentType[]>({
    url: '/segmentation/internal/v1/segment',
  })
