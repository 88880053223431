/* eslint-disable import/order */
import { TypedUseSelectorHook, useDispatch, useSelector, shallowEqual } from 'react-redux'
import { AnyAction, configureStore, Reducer } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { createLogger } from 'redux-logger'

// legacy redux declaration
import app from 'redux/app'
import bulkUpload from 'redux/bulkUpload'
import bulkUploadV2 from 'redux/bulkUploadV2'
import cloudKitchen from 'redux/cloudKitchen'
import productVendor from 'redux/productVendor'
import productVendorAdd from 'redux/productVendorAdd'
import refunds from 'redux/refunds'
import externalDriver from 'redux/externalDriver'
import loyaltyActivity from 'redux/loyaltyActivity'
import loyaltyEarnCoin from 'redux/loyaltyEarnCoin'
import loyaltyExchangeCoin from 'redux/loyaltyExchangeCoin'
import loyaltyHistoryCoin from 'redux/loyaltyHistoryCoin'
import purchaseRequest from 'redux/purchaseRequest'
import purchaseRequestDetail from 'redux/purchaseRequestDetail'
import purchaseRequestEdit from 'redux/purchaseRequestEdit'
import product from 'redux/product'
import freshPurchaseRequestDetail from 'redux/freshPurchaseRequestDetail'
import directPutaway from 'redux/directPutaway'
import freshPurchaseOrder from 'redux/freshPurchaseOrder'
import freshPurchaseOrderBulkUpload from 'redux/freshPurchaseOrderBulkUpload'
import freshPurchaseOrderDetail from 'redux/freshPurchaseOrderDetail'
import freshPurchaseOrderProcessInbound from 'redux/freshPurchaseOrderProcessInbound'
import freshPurchaseOrderTrimmingPacking from 'redux/freshPurchaseOrderTrimmingPacking'
import freshPurchaseOrderInbound from 'redux/freshPurchaseOrderInbound'
import freshPurchaseOrderInboundTrimmingPacking from 'redux/freshPurchaseOrderInboundTrimmingPacking'
import freshPurchaseTrimmingPackingDetail from 'redux/freshPurchaseTrimmingPackingDetail'
import printArea from 'redux/printArea'
import freshPurchaseOrderTrimmingPackingCheckQuantity from 'redux/freshPurchaseOrderTrimmingPackingCheckQuantity'
import supplyOrderAddProductDirect from 'redux/supplyOrderAddProductDirect'
import supplyOrderCheckQty from 'redux/supplyOrderCheckQty'
import brandMaster from 'redux/brandMaster'
import campaignAddManual from 'redux/campaignAddManual'
import supplyOrderApproval from 'redux/supplyOrderApproval'
import allOrder from 'redux/allOrder'
import addEditVoucher from 'redux/addEditVoucher'
import orderPooling from 'redux/orderPooling'
import uniqueVoucherList from 'redux/uniqueVoucherList'
import uniqueVoucherCampaignList from 'redux/uniqueVoucherCampaignList'
import uniqueVoucherPromoAddAndEdit from 'redux/uniqueVoucherPromoAddAndEdit'
import fraudSetting from 'redux/fraudSetting'
import blackAndWhiteList from 'redux/blackAndWhiteList'
import supplyOrderPicking from 'redux/supplyOrderPicking'
import stockOpnameList from 'redux/stockOpname/list'
import stockOpnameCheckAdjustment from 'redux/stockOpname/checkAdjustment'
import stockOpnameAdd from 'redux/stockOpname/add'
import constant from 'redux/constant'
import loadingGoods from 'redux/loadingGoods'
import promoPwp from 'redux/promoPwp'
// import banner from 'redux/banner'
import koliLost from 'redux/koliLost'
import filterLocationLists from 'redux/filterLocationLists'
import editPooling from 'redux/editPooling'
import referralMissionList from 'redux/referralMission/list'
import referralMissionAddAndEdit from 'redux/referralMission/addAndEdit'
import flagManagement from 'redux/flagManagement'
import campaign from 'redux/campaign'
import category from 'redux/category'
import catalogue from 'redux/catalogue'
import vendorBrandGrouping from 'redux/vendorBrandGrouping'
import orderDetail from 'redux/orderDetail'
import fraudReferral from 'redux/fraudReferral'
import fraudWatchTowerRule from 'redux/fraudWatchTowerRule'
import fraudWatchTowerQueryRule from 'redux/fraudWatcTowerQueryRule'
import fraudWatchTowerParameters from 'redux/fraudWatchTowerParameters'
import changeTimeslot from 'redux/changeTimeslot'
import disbursement from 'redux/disbursement'
import gwp from 'redux/gwp'
import promotionList from 'redux/promotion/list'
import promoWidget from 'redux/promoWidget'
import campaignSegment from 'redux/campaignSegment'
import routeAndScheduleAddEdit from 'redux/routeAndScheduleAddEdit'
import routeAndSchedule from 'redux/routeAndSchedule'
import productAssortment from 'redux/productAssortment'
import productTanggungRenteng from 'redux/productTanggungRenteng'
import tanggungRenteng from 'redux/tanggungRenteng'
import productLabel from 'redux/productLabel'
import abAdminCreateEdit from 'redux/abAdmin/abAdminCreateEdit'
import abAdminList from 'redux/abAdmin/abAdminList'
import allOrderV2 from 'features/Order/redux/AllOrder'
import campaignLoyaltyList from 'redux/loyalty/campaignLoyaltyList'
import campaignLoyaltyAddAndEdit from 'redux/loyalty/campaignLoyaltyAddAndEdit'
import campaignLoyaltyProductAddManual from 'redux/loyalty/campaignLoyaltyProductAddManual'
import { fullfilmentApi } from './services/api'
import order from './pages/Order/redux/order'
import packer from './pages/Order/redux/packer'
import referralVIPList from './pages/ReferralVIP/GetList/redux'
import dynamicLandingList from 'redux/dynamicLandingList'
import dynamicLanding from 'redux/dynamicLanding'
import reorderListV2 from 'redux/reorderListV2'
// --------- Legacy redux declaration ///

// new redux declaration //
import * as AuthFeatures from 'features/Auth/redux'
import * as AccountFeatures from 'features/Account/redux'
import * as CampaignAndDiscountFeatures from 'features/CampaignAndDiscount/redux'
import * as LoyaltyFeatures from 'features/Loyalty/redux'
import * as SupplyOrderFeatures from 'features/SupplyOrder/redux'
import * as FraudFeatures from 'features/Fraud/redux'
import * as LocationFeatures from 'features/Location/redux'
import * as AstroEventFeatures from 'features/AstroEvent/redux'
import * as ProductFeatures from 'features/Product/redux'
import * as PaymentFeatures from 'features/Payment/redux'
import * as PromotionFeatures from 'features/Promotion/redux'
import * as OrderFeatures from 'features/Order/redux'
import * as HRISFeatures from 'features/Enterprise/HRIS/redux'
import * as ReorderListFeatures from 'features/Enterprise/ReorderList/redux'
import * as CapacityHandlerFeatures from 'features/Enterprise/CapacityHandler/redux'
import * as SKPFeatures from 'features/Enterprise/SKP/redux'
import * as CategoryAndPosisiFeatures from 'features/CategoryAndPosisi/redux'
import * as HCUFeatures from 'features/HCU/redux'
import * as RackFeatures from 'features/Rack/redux'
import * as BannerAndTickerFeatures from 'features/BannerAndTicker/redux'
import * as VendorsFeatures from 'features/Enterprise/Vendor/redux'
import * as SOAutomationFeatures from 'features/Enterprise/SOAutomation/redux'
// new redux declaration //

export const createStore = (history: History) => {
  const loggerMiddleware = () => {
    const logger = createLogger({ collapsed: true })
    if (process.env.NODE_ENV !== 'production') {
      return logger
    }
    return [] as unknown as typeof logger
  }

  return configureStore({
    reducer: {
      router: connectRouter(history) as Reducer<RouterState, AnyAction>,
      app,
      bulkUpload,
      bulkUploadV2,
      filterLocationLists,
      productVendor,
      productVendorAdd,
      cloudKitchen,
      product,
      externalDriver,
      purchaseRequest,
      purchaseRequestDetail,
      freshPurchaseRequestDetail,
      purchaseRequestEdit,
      directPutaway,
      loyaltyEarnCoin,
      loyaltyExchangeCoin,
      loyaltyHistoryCoin,
      loyaltyActivity,
      refunds,
      freshPurchaseOrder,
      freshPurchaseOrderBulkUpload,
      freshPurchaseOrderDetail,
      freshPurchaseOrderProcessInbound,
      freshPurchaseOrderTrimmingPacking,
      freshPurchaseOrderInbound,
      freshPurchaseOrderInboundTrimmingPacking,
      freshPurchaseTrimmingPackingDetail,
      printArea,
      freshPurchaseOrderTrimmingPackingCheckQuantity,
      supplyOrderAddProductDirect,
      supplyOrderCheckQty,
      brandMaster,
      campaignAddManual,
      supplyOrderApproval,
      allOrder,
      addEditVoucher,
      orderPooling,
      uniqueVoucherList,
      uniqueVoucherCampaignList,
      uniqueVoucherPromoAddAndEdit,
      fraudSetting,
      blackAndWhiteList,
      supplyOrderPicking,
      stockOpnameList,
      stockOpnameCheckAdjustment,
      stockOpnameAdd,
      constant,
      loadingGoods,
      promoPwp,
      // banner,
      // timeSlot,
      // loadingDock,
      // inboundSchedule,
      // inboundScheduleAdd,
      koliLost,
      referralMissionList,
      referralMissionAddAndEdit,
      flagManagement,
      campaign,
      category,
      catalogue,
      vendorBrandGrouping,
      orderDetail,
      fraudReferral,
      fraudWatchTowerRule,
      fraudWatchTowerQueryRule,
      fraudWatchTowerParameters,
      disbursement,
      gwp,
      promotionList,
      promoWidget,
      productTanggungRenteng,
      tanggungRenteng,
      editPooling,
      routeAndScheduleAddEdit,
      routeAndSchedule,
      changeTimeslot,
      order,
      packer,
      campaignSegment,
      productAssortment,
      reorderListV2,
      productLabel,
      abAdminCreateEdit,
      campaignLoyaltyList,
      campaignLoyaltyAddAndEdit,
      campaignLoyaltyProductAddManual,
      referralVIPList,
      dynamicLanding,
      dynamicLandingList,
      [fullfilmentApi.reducerPath]: fullfilmentApi.reducer,
      abAdminList,
      allOrderV2,
      ...AuthFeatures,
      ...AstroEventFeatures,
      ...CampaignAndDiscountFeatures,
      ...AccountFeatures,
      ...LoyaltyFeatures,
      ...SupplyOrderFeatures,
      ...FraudFeatures,
      ...LocationFeatures,
      ...ProductFeatures,
      ...OrderFeatures,
      ...PaymentFeatures,
      ...PromotionFeatures,
      ...HRISFeatures,
      ...ReorderListFeatures,
      ...CapacityHandlerFeatures,
      ...SKPFeatures,
      ...CategoryAndPosisiFeatures,
      ...HCUFeatures,
      ...RackFeatures,
      ...BannerAndTickerFeatures,
      ...VendorsFeatures,
      ...SOAutomationFeatures,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(fullfilmentApi.middleware)
        .concat(routerMiddleware(history))
        .concat(loggerMiddleware()),
  })
}

export const history = createBrowserHistory()
export const store = createStore(history)

export type AppDispatchType = typeof store.dispatch
export type RootStateType = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector
export const useAppDispatch: () => AppDispatchType = useDispatch
export { shallowEqual }

setupListeners(store.dispatch)
