import apiServices from 'config/apiServiceApiGateway'
import type {
  GetMilestoneResponseType,
  GetMilestoneRequestType,
  PutMilestoneStatusRequestType,
} from 'features/Promotion/@types/miletstoneList'

const { Get, Put } = apiServices

export const getMilestoneList = ({ params }: GetMilestoneRequestType) =>
  Get<GetMilestoneResponseType, true>({
    url: '/promo/internal/v1/milestone/list',
    version: 'v1',
    params,
  })

export const putMilestoneStatus = ({ payload }: PutMilestoneStatusRequestType) =>
  Put({
    url: '/promo/internal/v1/milestone/status',
    version: 'v1',
    data: payload,
  })
