import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export interface GetListBulkUploadRequestType {
  service_id: number
  service_feature_id: number
  page_index: number
  page_size: number
}

export type StatusType = 'ON_PROGRESS' | 'SUCCESS' | 'PARTIAL_SUCCESS' | 'FAILED'

export interface GetListBulkUploadResponseType {
  id: number
  bulk_upload_service: {
    id: number
    name: string
  }
  bulk_upload_service_feature: {
    id: number
    name: string
  }
  status: StatusType
  filename: string
  uploaded_url: string // uploaded_url
  result_filename: string
  result_url: string
  failed_reason: string
  row_count: number
  success_count: number
  failed_count: number
  created_at: number
  created_by: string
  document_numbers: string[]
}

export interface ServiceStateType {
  id: number
  name: string
}

export interface FeatureStateType {
  id: number
  name: string
  template_url: string
}

export type CodeBulkUploadType =
  | ''
  | 'INVENTORY'
  | 'STOCK_OPNAME'
  | 'PRODUCT_CATALOGUE'
  | 'ERP_VENDOR_BRAND'
  | 'PROMO_PWP_TARGETED'
  | 'PROMO_GWP_TARGETED'
  | 'WMS_ROUTE_MASTER'
  | 'MASTER_PAP'
  | 'REORDER_LIST'
  | 'IMS_RACK'
  | 'INVENTORY_DISCOUNT_BMSM'
  | 'INVENTORY_DISCOUNT_CAMPAIGN'

interface GetServiceFeatureIdResponseType {
  bulk_upload_service_id: number
  id: number
  name: string
  template_url: string
}

export interface PostResponseType {
  message: string
  error: {
    status: string
    message: string
    code: number
  }
}

export const getListBulkUpload = (params: GetListBulkUploadRequestType) =>
  axiosInstanceApiGateway.Get<GetListBulkUploadResponseType[]>({
    url: '/upload/internal/v1/bulk-upload',
    params,
  })

export const postCreateBulkUploadTransaction = (formData: FormData) =>
  axiosInstanceApiGateway.Post<PostResponseType>({
    url: '/upload/internal/v2/bulk-upload',
    data: formData,
    contentType: 'multipart/form-data',
  })

export const getListOfServices = () =>
  axiosInstanceApiGateway.Get<ServiceStateType[]>({
    url: '/upload/internal/v1/bulk-upload/services',
  })

export const getListOfFeatureByService = (serviceId: number) =>
  axiosInstanceApiGateway.Get<FeatureStateType[]>({
    url: '/upload/internal/v1/bulk-upload/services/:serviceId/features',
    urlParams: {
      serviceId,
    },
  })

export const getServiceAndFeatureId = (code: CodeBulkUploadType) =>
  axiosInstanceApiGateway.Get<GetServiceFeatureIdResponseType>({
    url: `/upload/internal/v1/bulk-upload/service-feature`,
    params: {
      code,
    },
  })
