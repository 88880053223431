import { Get } from 'config/apiServiceConfig'
import { Product } from '../@types'

type GetProductsResponseType = {
  data: Product.ProductType[]
  pagination: Product.PaginationType
}

export type GetProductTypeResponseType = {
  data: {
    product_type_id: number
    product_type_name: string
  }[]
}

export const getProducts = (params: Partial<Product.QueryType>) =>
  Get<GetProductsResponseType>({
    url: '/api/ims/:version/products',
    version: 'v2',
    params,
  })

export const getProductType = () =>
  Get<GetProductTypeResponseType>({
    url: '/api/product/type',
  })

export const getProductById = (productId: number) =>
  Get<Product.ProductV1Type>({
    url: '/api/product/:productId',
    urlParams: { productId },
  })
