import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const CloseMessageConfig = lazy(() => import('../pages/CloseMessageConfig'))
const GlobalScheduledClose = lazy(() => import('../pages/GlobalScheduledClose'))

type NamedRoutesType = 'CloseMessageConfigRouter' | 'GlobalScheduledCloseRouter'
type PathListsType =
  | '/dashboard/location/close-message-config'
  | '/dashboard/location/global-scheduled-close'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const CloseMessageConfigRouter: RouterInstanceType = {
  name: 'CloseMessageConfigRouter',
  Component: CloseMessageConfig,
  path: '/dashboard/location/close-message-config',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_HO_OPX_HUB_MANAGER',
    'AUTH_HUB_AREA_MANAGER',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_PRODUCT_HEAD',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

const GlobalScheduledCloseRouter: RouterInstanceType = {
  name: 'GlobalScheduledCloseRouter',
  Component: GlobalScheduledClose,
  path: '/dashboard/location/global-scheduled-close',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_HO_OPX_HUB_MANAGER',
    'AUTH_HUB_AREA_MANAGER',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_PRODUCT_HEAD',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

const LocationRouter = [CloseMessageConfigRouter, GlobalScheduledCloseRouter]

export const LocationRouterDetail = generateRouterDetail(LocationRouter)

export default LocationRouter
