import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BuyerStateType, SetQueryPayloadType } from 'features/Account/@types/buyer'
import { SLICE_NAME, fetchBuyerList, updateBuyerStatus } from './buyerThunk'

const initialState: BuyerStateType = {
  isLoading: false,
  buyerList: [],
  query: { name: '', pageIndex: 0, pageSize: 20 },
}

const admin = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (state, action: PayloadAction<SetQueryPayloadType>) => {
      const { value, name } = action.payload

      state.query[name] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBuyerList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBuyerList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchBuyerList.fulfilled, (state, action) => {
        state.isLoading = false
        state.buyerList = action.payload.content.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })

      .addCase(updateBuyerStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateBuyerStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateBuyerStatus.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export default admin.reducer
export const { reset, setQuery } = admin.actions
