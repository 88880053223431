import axios from 'axios'
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const getPoolDataAPI = (payload) => axios.post(`${baseUrl}/api/picker/order/pooled`, payload)

export const tagPoolDataAPI = (payload) =>
  axios.post(`${baseUrl}/api/picker/order/tag-pool`, payload)

export const unTagPoolDataAPI = (payload) =>
  axios.post(`${baseUrl}/api/picker/order/untag-pool`, payload)

export const fetchUnpoolReasonsAPI = () => axios.get(`${baseUrl}/api/order/untag-pool/reason`)
